import { useEffect, useState } from 'react';

import RoundedButton from 'components/atoms/RoundedButton';
import { BottomArea, ButtonElementsArea, Title, TopArea, WholeArea } from 'components/atoms/ContentsArea';

import { trainingDatasetDeleteAPI, trainingDatasetGetAPI } from 'api/training-datasets';
import IconButton from 'components/molecules/IconButton';
import { useNavigate } from 'react-router-dom';
import cart_svg from 'assets/cart.svg';
import DatasetListTable, { TableTrainingdatasetType } from './DatasetListTable';
import ConfirmDialog from 'components/organisms/ConfirmDialog';
import colors from 'utils/colors';
import AlertDialog from 'components/organisms/AlertDialog';
import { checkNoneZero } from 'utils/checkSelectedNumber';
import convertDate from 'utils/convertDate';
import { trainingDatasetCartPostAPI } from 'api/training-datasets-carts';

/** データセット一覧画面 **/
export default function DatasetList() {
  // -- local states --
  const [table_bodies, setTableBodies] = useState<TableTrainingdatasetType[] | undefined>(undefined);
  const [selected_bodies, setSelectedBodies] = useState<TableTrainingdatasetType[]>([]);

  // 遷移を司るhooks
  const navigate = useNavigate();

  // -- handlers --
  // テーブルをクリックする関数
  const handleCheckClick = (bodies: TableTrainingdatasetType[]) => {
    setSelectedBodies(bodies);
  };

  const getTrainingDatasets = async () => {
    const return_table_datas: TableTrainingdatasetType[] = [];
    const training_datasets = await trainingDatasetGetAPI();

    if (training_datasets) {
      for (let i = 0; i < training_datasets.items.length; i++) {
        const return_table_data = {
          id: training_datasets.items[i].dataset_id,
          dataset_id: training_datasets.items[i].dataset_id,
          dataset_name: training_datasets.items[i].dataset_name,
          number_of_data: training_datasets.items[i].number_of_data,
          number_of_tags: JSON.stringify(training_datasets.items[i].number_of_tags),
          created_at: convertDate(training_datasets.items[i].created_at),
          detail_element: (
            <ButtonElementsArea>
              <RoundedButton
                onClick={() => handleCreateCartClick(training_datasets.items[i].dataset_id)}
                text="カートに入れる"
                style={{ width: 115, height: 25, marginRight: 12 }}
                stop_propagation
              />
            </ButtonElementsArea>
          ),
        };
        return_table_datas.push(return_table_data);
      }
      setTableBodies(return_table_datas);
    }
  };

  // カートに入れる
  const handleCreateCartClick = async (dataset_id: string) => {
    const res = await trainingDatasetCartPostAPI({
      dataset_id: dataset_id,
    });
    if (res) {
      AlertDialog.show(
        <div>
          カートにデータ追加処理を開始しました。
          <br />
          数分後にカート画面をご確認ください。
          <br />
          データ量によっては10分以上かかる場合があります。
        </div>
      );
    }
  };

  const handleDeleteClick = () => {
    ConfirmDialog.show(
      <div style={{ color: colors.red }}>[確認]選択されている学習データセットを削除してよろしいですか?</div>,
      () => handleOKClick(selected_bodies),
      undefined,
      undefined
    );
  };

  const handleOKClick = async (selected_datas: TableTrainingdatasetType[]) => {
    for (let i = 0; i < selected_datas.length; i++) {
      // 1つの学習データセットを削除
      await trainingDatasetDeleteAPI({ dataset_id: selected_datas[i].id });
    }
    await getTrainingDatasets();
    AlertDialog.show('学習データセットの削除に成功しました');
    setSelectedBodies([]);
  };

  // -- onload function --
  useEffect(() => {
    void (async function () {
      await getTrainingDatasets();
    })();
  }, []); /* eslint-disable-line */

  return (
    <WholeArea>
      <TopArea>
        <Title text="データセット一覧" />
        <IconButton
          img_src={cart_svg}
          style={{
            width: 45,
            height: 45,
            backgroundColor: 'transparent',
          }}
          onClick={() => navigate('/dataset/carts')}
        />
      </TopArea>
      <BottomArea>
        {table_bodies ? (
          <DatasetListTable
            bodies={table_bodies}
            selected_bodies={selected_bodies}
            handleCheckClick={handleCheckClick}
            footer_option={{
              text: '＞チェックした項目を削除する',
              handleClick: handleDeleteClick,
              disabled: checkNoneZero(selected_bodies.length),
            }}
          />
        ) : undefined}
      </BottomArea>
    </WholeArea>
  );
}
