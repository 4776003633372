import styled from 'styled-components';
import { colors } from 'utils/colors';
import styles from 'utils/styles';

/**
 * IDEA Platform UI 選択ボックス
 */
const RoundedSelect = styled.select<{
  long?: boolean;
}>`
  background-color: ${colors.white};
  border: 1px solid ${colors.component_small_border_color};
  border-radius: ${styles.border_radius};
  color: ${colors.main_font_color};
  font-family: inherit;
  height: ${styles.input_component_height};
  outline: none;
  padding: ${(params) => (params.long ? styles.input_box_padding : '0px 5px')};
  width: ${(params) => (params.long ? '100%' : '200px')};
  &::placeholder {
    color: ${colors.placeholder_color};
  }
`;
export default RoundedSelect;
