import { IObject } from 'utils/types';
import APIFetchJson, { APIFetchArrayBuffer } from './base/base';

/*** エンティティ ***/

export const sample_training_data1: TrainingData = {
  data_id: 1,
  image_s3_key: 'archive/1/cc59b0d4/81f5/af2e/94d6/c5abbf06bd68.jpg',
  tag_list: ['person', 'car'],
};

/** [GET] /api/training-datas */

export interface RequestTrainingDataPagingGetAPI {
  tag_list: string[];
  limit?: number;
  exclusive_start_key?: number;
}

export interface ResponseTrainingDataPagingGetAPI {
  items: TrainingData[];
  has_next: boolean;
  last_evaluated_data_id: number;
}

export interface TrainingData {
  data_id: number;
  image_s3_key: string;
  tag_list: string[];
}

export const trainingDataPagingGetAPI = async (
  props: RequestTrainingDataPagingGetAPI
): Promise<ResponseTrainingDataPagingGetAPI | null> => {
  const d: IObject<string> = { tag_list: props.tag_list.join(',') };
  if (typeof props.limit === 'number') d['limit'] = String(props.limit);
  if (typeof props.exclusive_start_key === 'number') d['exclusive_start_key'] = String(props.exclusive_start_key);
  const query_params = new URLSearchParams(d);
  const res = await APIFetchJson<ResponseTrainingDataPagingGetAPI>({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    path: `/api/training-datas?${query_params}`,
    method: 'GET',
    mock_res: {
      items: new Array(30).fill(null).map((_, i) => {
        const d = { ...sample_training_data1 };
        d.data_id = i;
        return d;
      }),
      has_next: true,
      last_evaluated_data_id: 1,
    },
  });
  return res;
};

/** [GET] /api/training-datas/image */

export interface RequestTrainingDatasImageGetAPI {
  image_s3_key: string;
}

export interface ResponseTrainingDatasImageGetAPI {
  url: ArrayBuffer;
}

export const trainingDatasImageGetAPI = async (props: RequestTrainingDatasImageGetAPI): Promise<Blob | null> => {
  const res = await APIFetchArrayBuffer({
    path: `/api/training-datas/image/${props.image_s3_key}`,
    method: 'GET',
    mock_res: new Blob(),
  });
  return res;
};

/** [POST] /api/training-datas/image/upload */

export interface RequestTrainingDatasImageUploadPostAPI {
  file_name: string;
}

export interface ResponseTrainingDatasImageUploadPostAPI {
  url: string;
}

export const trainingDatasImageUploadPostAPI = async (
  props: RequestTrainingDatasImageUploadPostAPI
): Promise<ResponseTrainingDatasImageUploadPostAPI | null> => {
  const res = await APIFetchJson<ResponseTrainingDatasImageUploadPostAPI>({
    path: '/api/training-datas/image/upload',
    method: 'POST',
    body: JSON.stringify(props),
    mock_res: {
      url: '',
    },
  });
  return res;
};

/** [POST] /api/training-datas/image/upload */

export interface RequestTrainingDatasImageUploadPutAPI {
  upload_url: string;
  file: File;
}

export const trainingDatasImageUploadPutAPI = async (
  props: RequestTrainingDatasImageUploadPutAPI
): Promise<Blob | null> => {
  // resultが他のapiのformの代わり
  // let result: string | ArrayBuffer | null = '';

  // // fileデータを読み込んでarraybufferに変換する処理
  // const fileLoader = new Promise((resolve) => {
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     result = reader.result;
  //     resolve(result);
  //   };
  //   try {
  //     reader.readAsArrayBuffer(props.file);
  //   } catch {
  //     console.log('fileの読み込みに失敗');
  //   }
  // });
  // await fileLoader;
  const res = await APIFetchArrayBuffer({
    path: props.upload_url,
    method: 'PUT',
    body: props.file,
    mock_res: props.file,
    headers: {
      'X-Amz-Acl': 'bucket-owner-full-control',
      'Content-Type': props.file.type,
    },
  });
  return res;
};
