import { LoggedInContext } from 'hooks/authContext/authContext';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';

/**
 * ログインしている時は、/にリダイレクトするRoute
 * **/
export interface PublicRouteProps {
  component: JSX.Element;
}
const PublicRoute = (props: PublicRouteProps): JSX.Element => {
  const { logged_in, loading } = useContext(LoggedInContext);
  // loadが終了していて、ログインしている時は、/画面に戻る
  if (!loading && logged_in) {
    return <Navigate replace to="/" />;
  } else {
    return props.component;
  }
};

export default PublicRoute;
