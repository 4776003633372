// -- basic library --
import React from 'react';
import styled from 'styled-components';

// -- external components --
import { colors } from 'utils/colors';
import IconButton from 'components/molecules/IconButton';
import FunctionalText from 'components/atoms/FunctionalText';
import { TableBodyType } from 'components/molecules/BaseTable';

import table_icon_page_right from 'assets/table_icon_page_right.png';
import table_icon_page_left from 'assets/table_icon_page_left.png';

// -- type declaration --
interface Params {
  handleClick: (num: number) => void;
  handleAddDatas?: () => void;
  has_next?: boolean;
  selected_page_number: number;
  bodies: TableBodyType[];
  bodies_per_page: number;
  page_display_number: number;
  style?: React.CSSProperties;
}

// -- main component --

const PageTurningComponentForTable: React.FC<Params> = (params) => {
  // 一番後ろの番号
  const last_page_number = Math.ceil(params.bodies.length / params.bodies_per_page);

  // [1,NaN, 5,6,7,8,9, NaN,11]のように、表示するテーブル番号を返却する(NaNの時は"…")
  const getIndexAllocations = (selected_number: number, last_page_number: number): number[] => {
    // …も含めて最大いくつ表示することになるか
    // 例えばparams.page_display_number=5の時
    // [1,…, 3,4,5,6,7,…,9]のように長さ9となる。
    // つまり表示数+4とすれば良い
    // この表示数を合わせないと、右左の矢印の位置が毎回変わってしまう。
    const max_display_number = params.page_display_number + 4;
    // max_display_numberの中央値(切り捨て)
    const mmedian = ~~(max_display_number / 2);
    // 表示する番号を格納する配列
    // NaNの時は"…"を表示する
    let allocations: number[] = [];
    // 選択されている番号が表示数の中央値以下の場合は、[1,2,3,4,5,6,7,8,9]のように1からmax_display_numberの分だけ
    if (selected_number <= mmedian) {
      const newallo = new Array(max_display_number)
        .fill(null)
        .map((_, i) => {
          // 最大バリデーション
          if (i >= last_page_number) {
            return undefined;
          }
          return i + 1;
        })
        .filter((e) => e !== undefined) as number[];
      allocations = newallo;
    } else if (selected_number >= last_page_number - mmedian) {
      // 選択されている番号が「最後 - 表示数の中央値」の時は、[11,10,9,8,7,6,5,4,3]のようにして、反転する。
      const newallo = new Array(max_display_number)
        .fill(null)
        .map((_, i) => {
          // 最大バリデーション
          if (i >= last_page_number) {
            return undefined;
          }
          return last_page_number - i;
        })
        .filter((e) => e !== undefined) as number[];
      newallo.reverse();
      allocations = newallo;
    } else {
      const newallo = new Array(max_display_number)
        .fill(null)
        .map((_, i) => {
          const num = selected_number + i - mmedian;
          return num;
        })
        .filter((e) => e !== undefined);
      allocations = newallo;
    }
    // 現時点のallocationsは最初と最後の要素を必ず表示していない。73~84行目の式で対応する。
    if (allocations.length >= 2) {
      // 最初が1でなければ、最初を1とし、次を"‥"とする。
      if (allocations[0] >= 2) {
        allocations[0] = 1;
        allocations[1] = NaN;
      }
    }
    // 最後がlast_page_numberでなければ、最後をlast_page_numberとし、その手前を"‥"とする。
    if (allocations[allocations.length - 1] <= last_page_number - 1) {
      allocations[allocations.length - 1] = last_page_number;
      allocations[allocations.length - 2] = NaN;
    }
    return allocations;
  };

  // データを追加する関数
  const handleAddDatas = () => {
    if (params.handleAddDatas && params.has_next) {
      params.handleAddDatas();
    }
  };

  // 左側の矢印が押された時の関数
  const handleLeftClick = () => {
    params.handleClick(Math.max(params.selected_page_number - 1, 1));
  };

  // 右側の矢印が押された時の関数
  const handleRightClick = () => {
    params.handleClick(Math.min(params.selected_page_number + 1, last_page_number));
  };

  // ページング番号が押された時の関数
  const handleNumberClick = (allo: number) => {
    params.handleClick(allo);
  };

  // -- render part --
  return (
    <WholeAreaForPageTurningComponent style={params.style}>
      <IconButton img_src={table_icon_page_left} onClick={handleLeftClick} style={{ width: 25, height: 25 }} />
      {getIndexAllocations(params.selected_page_number, last_page_number).map((allo, index) => {
        // 数値がNaNの場合はdot文字を出力
        const dot = isNaN(allo);
        return (
          <NumberText key={index}>
            <BFunctionalText
              text={dot ? '…' : String(allo)}
              onClick={dot ? undefined : () => handleNumberClick(allo)}
              hideDecoration={dot}
              disabled={dot}
              selected={params.selected_page_number === allo}
            />
          </NumberText>
        );
      })}
      {params.has_next && (
        <PlusText>
          <BFunctionalText style={{ fontSize: 'larger' }} text="+" onClick={handleAddDatas} hideDecoration={true} />
        </PlusText>
      )}
      <IconButton img_src={table_icon_page_right} onClick={handleRightClick} style={{ width: 25, height: 25 }} />
    </WholeAreaForPageTurningComponent>
  );
};

// -- styled components --
const WholeAreaForPageTurningComponent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NumberText = styled.div`
  min-width: 20px;
  margin: 0px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PlusText = styled.div`
  min-width: 20px;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BFunctionalText = styled(FunctionalText)<{
  selected?: boolean;
  hideDecoration?: boolean;
}>`
  font-weight: bold;
  text-decoration: ${(p) => (p.hideDecoration ? 'none' : 'underline')};
  ${(p) => (p.selected ? `color: ${colors.component_main_color}` : null)}
`;

// -- finally export part --

export default PageTurningComponentForTable;
