/**
 * API通信を行う関数の雛形
 * **/

import AuthContextUpdater from 'components/organisms/AuthContextUpdater';
import { IObject } from 'utils/types';
// import handler from './handler';

export type APIMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

export interface APIFetchJsonProps<T> {
  path: string;
  method: APIMethod;
  mock_res: T;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: BodyInit | null;
  headers?: HeadersInit;
  init?: RequestInit;
}
const APIFetchJson = async <T,>(props: APIFetchJsonProps<T>): Promise<T | null> => {
  // 本番以外は、mockデータを返却する。
  if (process.env.NODE_ENV !== 'production') {
    return props.mock_res;
  }
  const init: RequestInit = {
    ...props.init,
    headers: {
      'Content-Type': 'application/json',
      ...props.headers,
    },
    method: props.method,
    body: props.body,
  };

  // リクエスト部分
  const res = await fetch(props.path, init);
  console.log(res);
  if (res.status === 200) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
    const json: IObject<any> = await res.json();
    if (Object.keys(json).length > 0) {
      return json as Promise<T>;
    } else {
      return null;
    }
  } else if (res.status === 401) {
    AuthContextUpdater.signOut();
  }
  return null;
};

/***
 * API通信で、特にArraybufferを送信する用の
 * ***/
export interface APIFetchArrayBufferProps {
  path: string;
  method: APIMethod;
  mock_res: Blob;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: BodyInit | null;
  headers?: HeadersInit;
  init?: RequestInit;
}
export const APIFetchArrayBuffer = async (props: APIFetchArrayBufferProps): Promise<Blob | null> => {
  // 本番以外は、mockデータを返却する。
  if (process.env.NODE_ENV !== 'production') {
    return props.mock_res;
  }
  const init: RequestInit = {
    ...props.init,
    headers: {
      ...props.headers,
    },
    method: props.method,
    body: props.body,
  };

  const res = await fetch(props.path, init);

  if (res.status === 200) {
    return res.blob();
  } else if (res.status === 401) {
    AuthContextUpdater.signOut();
  }
  return null;
};

export default APIFetchJson;
