/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

export const checkZero = (selectedNumber: number) => {
  if (selectedNumber === 0) {
    return false;
  } else {
    return true;
  }
};

export const checkNoneZero = (selectedNumber: number) => {
  if (selectedNumber >= 1) {
    return false;
  } else {
    return true;
  }
};

export const checkNoneZeroAndActive = (datas_with_status: any[]) => {
  if (datas_with_status.length < 1 || datas_with_status.find((v) => v.status === 'DELETED')) {
    return true;
  }
  return false;
};

export const checkOne = (selectedNumber: number) => {
  if (selectedNumber === 1) {
    return false;
  } else {
    return true;
  }
};
