// -- basic library --

// -- http connection library --

// -- external components --
import GnDialog, { GnDialogProps } from 'components/atoms/GnDialog';
import { Spinner } from '@blueprintjs/core';
import { Weaken } from 'utils/types';

// -- external datas --

// -- external functions --

// -- external types --
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ProcessingDialogProps extends Weaken<GnDialogProps, 'onClose'> {
  title?: string;
  onClose: (cancel?: boolean) => void;
}

// -- main component --
const ProcessingDialog = (props: ProcessingDialogProps) => {
  // -- render part --
  return (
    <GnDialog
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      isOpen={props.isOpen}
      onClose={() => props.onClose(true)}
      title={props.title ? props.title : '処理中'}
      canOutsideClickClose={false}
    >
      <div>しばらくお待ちください。時間がかかる場合があります。</div>
      <Spinner />
    </GnDialog>
  );
};

// -- finally export part --

export default ProcessingDialog;
