/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/***
 * window.sessionStrageを操作する関数
 * ***/

import { IObject } from 'utils/types';

/***
 * sessionStorageのデータを安全に取得する関数
 * ***/
export const getSessionStorage = (key: string) => {
  const sessionStorage = window.sessionStorage;
  const ss = sessionStorage.getItem(key);
  return ss || '';
};

/***
 * テーブルのpageNumberを安全に取得する関数
 * ***/
export const getPageNumberFromSessionStorage = (tableName: string, defaultNum = 1) => {
  const pn = getSessionStorage('pageNumbers');
  let pn_json: IObject<number> = {};
  if (pn) {
    pn_json = JSON.parse(pn);
  }
  const table_pn = pn_json[tableName];
  // 宣言がない時はdefault_num
  const pageNumber = table_pn || defaultNum;
  return pageNumber;
};

/***
 * テーブルのpageNumberを安全に更新する関数
 * ***/
export const setPageNumberFromSessionStorage = (tableName: string, pageNumber: number) => {
  // window.sessionStrageのpageNumbersは
  // {[table_name: string]: number}
  // のJSONで保持します(正確にはJSONのstringify)
  const pn = getSessionStorage('pageNumbers');
  let pn_json: IObject<number> = {};
  if (pn) {
    pn_json = JSON.parse(pn);
  }
  pn_json[tableName] = pageNumber;
  window.sessionStorage.setItem('pageNumbers', JSON.stringify(pn_json));
};

/***
 * テーブルのpageNumberをリセットする。
 * ***/
export const resetPageNumberFromSessionStorage = () => {
  window.sessionStorage.removeItem('pageNumbers');
};
