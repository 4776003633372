import { TableBodyType } from 'components/molecules/BaseTable';

const getTablePageDisplayNumber = (
  selected_page_number: number,
  bodies: TableBodyType[],
  body_display_number: number,
  has_next?: boolean
): string => {
  let return_text = '';
  const last_page = Math.ceil(bodies.length / body_display_number) === selected_page_number;
  let start_number = 1 + (selected_page_number - 1) * body_display_number;
  let end_number = last_page ? bodies.length : start_number - 1 + body_display_number;
  if (bodies.length <= 0) {
    start_number = 0;
    end_number = 0;
  }
  const has_next_text = has_next ? '+' : '';
  return_text = `${start_number}〜${end_number} / ${bodies.length}${has_next_text}件`;
  return return_text;
};

export default getTablePageDisplayNumber;
