import styled from 'styled-components';
import colors from 'utils/colors';
import styles from 'utils/styles';

// ログインエリア
export const LoginBackGround = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  min-width: 450px;
  overflow: hidden;
  display: block;
  justify-content: center;
  align-items: center;
  background-color: ${colors.background_color};
`;

export const LoginArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 475px;
  height: 550px;
  border-radius: 5%;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  margin: auto;
  pointer-events: all !important;
  opacity: 1;
  text-align: center;
  background-color: ${colors.white};
  padding: ${styles.login_padding_top} ${styles.login_padding_side};
  box-shadow: 0 0 0 1px rgb(16 22 26 / 10%), 0 1px 1px rgb(16 22 26 / 20%), 0 2px 6px rgb(16 22 26 / 20%);
`;

export const LoginBaseArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const LoginNormalText = styled.div`
  font-size: 14px;
`;
