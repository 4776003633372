import { LoggedInContext } from 'hooks/authContext/authContext';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';

/**
 * ログインしていない時は、/loginページにリダイレクトするRoute
 * **/
export interface PrivateRouteProps {
  component: JSX.Element;
}
const PrivateRoute = (props: PrivateRouteProps): JSX.Element => {
  const { logged_in, loading } = useContext(LoggedInContext);
  // loadが終了していて、ログインされていない時は、/login画面に戻る
  if (!loading && !logged_in) {
    const url = window.location.href;
    const to = '/login?redirect=' + encodeURIComponent(url);
    return <Navigate replace to={to} />;
  } else {
    return props.component;
  }
};

export default PrivateRoute;
