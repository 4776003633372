// -- basic library --
import { useState } from 'react';
import styled from 'styled-components';

// -- external components --

// -- external datas --
import pw_on_svg from 'assets/pw_on.svg';
import pw_off_svg from 'assets/pw_off.svg';
import InputBox, { InputBoxProps } from 'components/atoms/InputBox';

// -- type declaration --
interface InputBoxWithIconProps extends InputBoxProps {
  icon_src?: string;
  password?: boolean;
}

// -- main component --

const InputBoxWithIcon = (props: InputBoxWithIconProps) => {
  const [reveal, setReveal] = useState(false);

  // -- render part --
  return (
    <InputArea style={props.style}>
      <InputBox {...props} type={props.password && reveal === false ? 'password' : 'text'} />
      {/* <IconLeft src={props.icon_src} /> */}
      {props.password ? <IconRight src={reveal ? pw_on_svg : pw_off_svg} onClick={() => setReveal(!reveal)} /> : null}
    </InputArea>
  );
};

// -- styled components --

const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
`;

const IconRight = styled.img`
  position: absolute;
  top: calc((40px - 24px) / 2);
  right: calc((40px - 24px) / 2);
  width: 24px;
  height: 24px;
`;

// -- finally export part --

export default InputBoxWithIcon;
