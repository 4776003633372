// idea-platform-uiux用新カラーパレット
export const colors = {
  // generic color codes
  black: 'black',
  gray: '#e0e0e0',
  dark_gray: '#666666',
  white: '#FFFFFF',
  red: 'red',
  blue: 'blue',
  yellow: 'yellow',
  light_green: 'rgba(206,238,154,1)',
  // named color codes
  background_color: '#f5f5f5',
  text_color: 'black',
  shadow_color: 'black',
  // purposed color codes
  component_small_border_color: '#919191',
  component_main_color: 'rgba(57, 131, 141, 1)',
  component_border_color: '#52b7bc',
  component_text_color: '#FFFFFF',
  placeholder_color: 'rgba(170, 170, 170, 0.8)',
  selected_border_color: '#4497ca',
  disabled_background_color: 'rgba(204, 204, 204, 1)',
  toggle_background_color: '#d9d9d9',
  icon_safe_color: '#2dcd98',
  icon_dangerous_color: '#f45867',
  main_font_color: '#6a6a6a',
  table_hover_background_color: 'rgba(0, 0, 0, 0.04)',
  table_checked_background_color: 'rgba(0, 136, 145, 0.3)',
  alert_color: 'rgba(226, 99, 107, 1)',
  error_color: 'rgba(231, 207, 209)',
  crosslineLRColor: '#F06D00', // 検知ラインのLR color オレンジ色
  crosslineRLColor: '#0094E4', // 検知ラインのRL color 優しい青色
};

export default colors;
