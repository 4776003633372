// -- basic library --
import { ReactNode, useContext } from 'react';
import styled from 'styled-components';
import styles from 'utils/styles';
import colors from 'utils/colors';

// -- external components --
import GnFooter from 'components/atoms/GnFooter';
import GnSideMenu from './GnSideMenu';
import GnHeader from './GnHeader';
import { LoggedInContext } from 'hooks/authContext/authContext';
import AlertDialog from './AlertDialog';
import AuthContextUpdater from './AuthContextUpdater';
import ConfirmDialog from './ConfirmDialog';

interface GnDocumentProps {
  children?: ReactNode;
}

/**
 * ページ全体
 */
const GnDocument = (props: GnDocumentProps) => {
  const { logged_in } = useContext(LoggedInContext);
  if (logged_in) {
    return (
      <StyledDocument className="base">
        <GnSideMenu />
        <Body>
          <GnHeader />
          <Content>{props.children}</Content>
        </Body>
        <AlertDialog />
        <ConfirmDialog />
        <AuthContextUpdater />
        <GnFooter />
      </StyledDocument>
    );
  } else {
    return <Content>{props.children}</Content>;
  }
};

// -- styled components --
const StyledDocument = styled.div`
  display: flex;
  position: relative;
  pointer-events: 'all';
  height: 100vh;
  width: 100vw;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100% - ${styles.sidemenu_width});
  background-color: ${colors.background_color};
`;

const Content = styled.div`
  width: 100%;
  height: calc(100% - ${styles.topmenu_height} - ${styles.footer_height});
  padding-top: 1px;
  overflow: hidden;
  overflow-y: scroll;
`;

// -- finally export part --

export default GnDocument;
