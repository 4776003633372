import APIFetchJson from './base/base';

export const sample_training_dataset1: TrainingDataset = {
  tenant_id: '00000001',
  dataset_id: '00000001',
  dataset_name: 'test01',
  number_of_data: 1000,
  number_of_tags: "{'person': 1000}",
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00',
  updated_at: '2020-01-01T00:00:00',
  deleted_at: '2020-01-01T00:00:00',
};

/** [GET] /api/training-dataset */

export interface RequestTrainingDatasetGetAPI {
  dataset_name: string;
}

export interface ResponseTrainingDatasetGetAPI {
  items: TrainingDataset[];
  has_next: boolean;
  last_evaluated_data_id: number;
}

export interface TrainingDataset {
  tenant_id: string;
  dataset_id: string;
  dataset_name: string;
  number_of_data: number;
  number_of_tags: string;
  status: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

export const trainingDatasetGetAPI = async (): Promise<ResponseTrainingDatasetGetAPI | null> => {
  const res = await APIFetchJson<ResponseTrainingDatasetGetAPI>({
    path: '/api/training-dataset',
    method: 'GET',
    mock_res: {
      items: [sample_training_dataset1],
      has_next: true,
      last_evaluated_data_id: 1,
    },
  });
  return res;
};

/** [POST] /api/training-dataset */

export interface RequestTrainingDatasetPostAPI {
  dataset_name: string;
}

export interface ResponseTrainingDatasetPostAPI {
  succeed: boolean;
}

export const trainingDatasetPostAPI = async (
  props: RequestTrainingDatasetPostAPI
): Promise<ResponseTrainingDatasetPostAPI | null> => {
  const res = await APIFetchJson<ResponseTrainingDatasetPostAPI>({
    path: '/api/training-dataset',
    method: 'POST',
    body: JSON.stringify(props),
    mock_res: {
      succeed: true,
    },
  });
  return res;
};

/** [DELETE] /api/training-dataset/{data_id} */

export interface RequestTrainingDatasetDeleteAPI {
  dataset_id: string;
}

export interface ResponseTrainingDatasetDeleteAPI {
  succeed: boolean;
}

export const trainingDatasetDeleteAPI = async (
  props: RequestTrainingDatasetDeleteAPI
): Promise<ResponseTrainingDatasetDeleteAPI | null> => {
  const res = await APIFetchJson<ResponseTrainingDatasetDeleteAPI>({
    path: `/api/training-dataset`,
    method: 'DELETE',
    body: JSON.stringify(props),
    mock_res: {
      succeed: true,
    },
  });
  return res;
};
