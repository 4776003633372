import APIFetchJson from './base/base';

/** [GET] /api/traing-tags */

export interface ResponseTrainingTagsGetAPI {
  tag_list: string[];
}

export const trainingTagsGetAPI = async (): Promise<ResponseTrainingTagsGetAPI | null> => {
  const res = await APIFetchJson<ResponseTrainingTagsGetAPI>({
    path: '/api/training-tags',
    method: 'GET',
    mock_res: {
      tag_list: ['a', 'b'],
    },
  });
  console.log(res);
  return res;
};
