// -- basic library --
import React from 'react';
import styled from 'styled-components';

// -- external components --
import IconButton from 'components/molecules/IconButton';
import table_icon_sort_top from 'assets/table_icon_sort_top.png';
import table_icon_sort_bottom from 'assets/table_icon_sort_bottom.png';

// -- type declaration --
interface Params {
  handleClick1: () => void;
  handleClick2: () => void;
}

// -- main component --

const SortIconForTable: React.FC<Params> = (params) => {
  // -- preparations --

  // -- render part --
  return (
    <WholeAreaForSortIcon>
      <IconButton img_src={table_icon_sort_top} onClick={params.handleClick1} style={{ width: 10, height: 10 }} />
      <IconButton img_src={table_icon_sort_bottom} onClick={params.handleClick2} style={{ width: 10, height: 10 }} />
    </WholeAreaForSortIcon>
  );
};

// -- styled components --
const WholeAreaForSortIcon = styled.div`
  width: 10px;
  height: auto;
  margin: 0px 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

// -- finally export part --

export default SortIconForTable;
