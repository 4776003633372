import { Spinner } from '@blueprintjs/core';
import { useEffect, useState } from 'react';

import MultiSelectBox from 'components/atoms/MultiSelectBox';
import RoundedButton from 'components/atoms/RoundedButton';
import FileCardDisplayingTrainingData from 'components/organisms/FileCardDisplayingTrainingData';
import { CardArea, Title, TopArea, TopButtonArea, WholeArea } from 'components/atoms/ContentsArea';

import { TrainingData, trainingDataPagingGetAPI } from 'api/training-datas';
import { trainingTagsGetAPI } from 'api/training-tags';
import PageTurningComponentForTrainingDataTable from 'components/molecules/PageTurningComponentForTrainingDataTable';

// -- external data --
import cart_svg from 'assets/cart.svg';
import { useNavigate } from 'react-router-dom';
import IconButton from 'components/molecules/IconButton';
import { trainingDatasetCartPostAPI } from 'api/training-datasets-carts';
import AlertDialog from 'components/organisms/AlertDialog';

// 一度に取得する学習データの個数
const GET_TRAINING_DATA_LIMIT = 100;

// 1ページに表示する学習データの個数
const TRAINING_DATAS_PER_PAGE = 10;

// ページ切り替えボタンの数字の表示数
// 例えば5ならば <- 1 2 3 4 5 -> となる
const page_display_number = 5;

/** 学習データ一覧画面 **/
export default function Dataset() {
  const [trainingData, setTrainingData] = useState<TrainingData[] | undefined>(undefined);
  const [selectedTagList, setSelectedTagList] = useState<string[]>([]);
  const [tagList, setTagList] = useState<string[]>([]);
  const [exclusive_start_key, setExclusiveStartKey] = useState<number | undefined>(undefined);
  const [has_next, setHasNext] = useState<boolean>(false);
  const [selected_page_number, setSelectedPageNumber] = useState<number>(1);
  // 遷移を司るhooks
  const navigate = useNavigate();

  // 学習データの取得
  const getTrainingData = async () => {
    const res = await trainingDataPagingGetAPI({
      tag_list: selectedTagList,
      limit: GET_TRAINING_DATA_LIMIT,
    });
    if (res) {
      setTrainingData(res.items);
      setExclusiveStartKey(res.last_evaluated_data_id ? res.last_evaluated_data_id : undefined);
      setHasNext(res.has_next);
    }
  };

  // 次ページの学習データを追加
  const addDatas = async () => {
    // 次のデータがなければ、この関数を呼ばない
    if (!has_next || !exclusive_start_key) {
      return;
    }
    const res = await trainingDataPagingGetAPI({
      tag_list: selectedTagList,
      limit: GET_TRAINING_DATA_LIMIT,
      exclusive_start_key: exclusive_start_key,
    });
    if (res) {
      let training_datas = [];
      if (trainingData === undefined) {
        training_datas = res.items;
      } else {
        training_datas = [...trainingData, ...res.items];
      }
      setTrainingData(training_datas);
      setExclusiveStartKey(res.last_evaluated_data_id ? res.last_evaluated_data_id : undefined);
      setHasNext(res.has_next);
    }
  };

  // タグ一覧取得
  const getTagList = async () => {
    const res = await trainingTagsGetAPI();
    if (res) {
      setTagList(res.tag_list);
    }
  };

  // タグを追加
  const handleTagItemsChange = (item: string) => {
    const list = [...selectedTagList];
    if (!list.includes(item)) {
      list.push(item);
      setSelectedTagList(list);
    }
  };

  // タグを除去
  const handleTagItemsRemove = (index: number | undefined) => {
    const list = [...selectedTagList];
    if (typeof index === 'number') list.splice(index, 1);
    setSelectedTagList(list);
  };

  // 全てカートに入れる
  const handleCreateCartClick = async () => {
    const res = await trainingDatasetCartPostAPI({
      tag_list: selectedTagList,
    });
    if (res) {
      if (res.succeed) {
        AlertDialog.show(
          <div>
            カートにデータ追加処理を開始しました。
            <br />
            数分後にカート画面をご確認ください。
            <br />
            データ量によっては10分以上かかる場合があります。
          </div>
        );
      } else {
        AlertDialog.show('該当データはありませんでした');
      }
    }
  };

  // -- onload function --
  useEffect(() => {
    void (async function () {
      await getTrainingData();
    })();
  }, [selectedTagList]); /* eslint-disable-line */

  useEffect(() => {
    void (async function () {
      await getTagList();
    })();
  }, []); /* eslint-disable-line */

  return (
    <WholeArea>
      <TopArea>
        <Title text="学習データ一覧" />
        <IconButton
          img_src={cart_svg}
          style={{
            width: 45,
            height: 45,
            backgroundColor: 'transparent',
          }}
          onClick={() => navigate('/dataset/carts')}
        />
      </TopArea>
      <TopButtonArea style={{ justifyContent: 'space-between' }}>
        <div>
          タグ入力
          <MultiSelectBox
            selectedItems={selectedTagList}
            items={tagList}
            onItemSelect={(item) => handleTagItemsChange(item)}
            onRemove={(item, index) => handleTagItemsRemove(index)}
          />
        </div>
        <RoundedButton
          text="全てカートに入れる"
          onClick={handleCreateCartClick}
          disabled={selectedTagList.length === 0}
        />
      </TopButtonArea>
      <div style={{ width: '300px', margin: 'auto' }}>
        {trainingData ? (
          <PageTurningComponentForTrainingDataTable
            handleAddDatas={() => addDatas()}
            has_next={has_next}
            handleClick={setSelectedPageNumber}
            selected_page_number={selected_page_number}
            bodies_per_page={TRAINING_DATAS_PER_PAGE}
            bodies={trainingData}
            page_display_number={page_display_number}
          />
        ) : undefined}
      </div>
      <CardArea>
        {/* 開始 */}
        {trainingData ? (
          trainingData
            .slice((selected_page_number - 1) * TRAINING_DATAS_PER_PAGE, selected_page_number * TRAINING_DATAS_PER_PAGE)
            .map((data, index) => {
              return (
                <FileCardDisplayingTrainingData
                  data_id={data.data_id}
                  image_s3_key={data.image_s3_key}
                  tag_list={data.tag_list}
                  key={index}
                />
              );
            })
        ) : (
          <div>
            ～取得中～ 10秒ほどお待ちください
            <Spinner />
          </div>
        )}
      </CardArea>
    </WholeArea>
  );
}
