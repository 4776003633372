/**
 * 日付を YYYY/MM/DD hh:mm:ss 形式の文字列で返します。
 * そのような変換ができない場合は、空文字列を返します。
 *
 * @param date 日付を表す文字列や、ミリ秒のタイムスタンプ
 * @returns 日付を表す文字列、または空文字列。
 */
export default function convertDate(date: number | string | Date) {
  if (!date) return '';
  try {
    const dt = new Date(date);
    const year = ('0000' + String(dt.getFullYear())).slice(-4);
    const month = ('00' + String(dt.getMonth() + 1)).slice(-2);
    const day = ('00' + String(dt.getDate())).slice(-2);
    const hour = ('00' + String(dt.getHours())).slice(-2);
    const minute = ('00' + String(dt.getMinutes())).slice(-2);
    const second = ('00' + String(dt.getSeconds())).slice(-2);
    return `${year}/${month}/${day} ${hour}:${minute}:${second}`;
  } catch (e) {
    return '';
  }
}
