import { RequestUsersLoginByPlatformPostAPI } from 'api/users';
import { checkSpace } from './chekers';

/** ログイン時のバリデーション関数**/
export const validateLoginPost = (post_data: RequestUsersLoginByPlatformPostAPI): Error | void => {
  const { login_id, password, tenant_id, platform_env } = post_data;
  // データの長さにおけるヴァリデーション
  if (!checkSpace(platform_env)) {
    throw new Error(`platform環境名を入力してください`);
  }
  if (!checkSpace(tenant_id)) {
    throw new Error(`テナントIDを入力してください`);
  }
  if (!checkSpace(login_id)) {
    throw new Error(`ログインIDを入力してください`);
  }
  if (!checkSpace(password)) {
    throw new Error(`パスワードを入力してください`);
  }
};

/** dataset_nameのバリデーション関数**/
export const validateDatasetName = (dataset_name: string): Error | void => {
  // データの長さにおけるヴァリデーション
  if (!checkSpace(dataset_name)) {
    throw new Error(`データセット名を入力してください`);
  }
};
