import { SetStateAction, useState } from 'react';

export interface UseFileProps {
  init_file?: FileType;
}

export interface FileType {
  file_name: string;
  file: File;
}

const useFile = (
  props?: UseFileProps
): [FileType | undefined, React.Dispatch<SetStateAction<FileType | undefined>>] => {
  const [file, setFile] = useState<FileType | undefined>(props?.init_file);

  return [file, setFile];
};

export default useFile;
