// -- basic library --
import React from 'react';
import styled from 'styled-components';

// -- external components --
import colors from 'utils/colors';
import styles from 'utils/styles';

// -- type declaration --
interface IconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  img_src: string;
  img_style?: React.CSSProperties;
}

// -- main component --

const IconButton = (props: IconButtonProps) => {
  // -- preparations --

  // -- render part --
  return (
    <Button {...props}>
      <IconImg src={props.img_src} style={props.img_style} />
    </Button>
  );
};

// -- styled components --

const Button = styled.button`
  display: flex;
  width: auto;
  height: auto;
  box-shadow: none;
  border: none;
  cursor: pointer;
  padding: 0px;
  background-color: ${colors.white};
  &:disabled {
    cursor: not-allowed !important;
    opacity: ${styles.opacity_disabled};
    background-color: ${colors.white} !important;
  }
  &:hover {
    opacity: ${(params) => (params.disabled ? styles.opacity_disabled : styles.opacity_hover)};
    cursor: ${(params) => (params.disabled ? 'not-allowed' : 'pointer')};
  }
`;

const IconImg = styled.img`
  width: 100%;
  height: 100%;
`;

// -- finally export part --

export default IconButton;
