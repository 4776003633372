// -- css parts --
import './App.css';
import 'normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/select/lib/css/blueprint-select.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';

// -- basic library --
import { Route, Routes, BrowserRouter } from 'react-router-dom';

// -- external pages --
import Login from 'pages/Login';
import Tops from 'pages/Tops';
import Dataset from 'pages/Dataset';
import GnDocument from 'components/organisms/GnDocument';
import PrivateRoute from 'components/atoms/PrivateRoute';
import PublicRoute from 'components/atoms/PublicRoute';
import Carts from 'pages/Carts';
import DatasetList from 'pages/DatasetList';

const App = () => {
  return (
    <BrowserRouter>
      <GnDocument>
        <Routes>
          <Route path="/login" element={<PublicRoute component={<Login />} />} />
          <Route index element={<PrivateRoute component={<Tops />} />} />
          <Route path="/dataset" element={<PrivateRoute component={<Dataset />} />} />
          <Route path="/dataset/carts" element={<PrivateRoute component={<Carts />} />} />
          <Route path="/datasetList" element={<PrivateRoute component={<DatasetList />} />} />
        </Routes>
      </GnDocument>
    </BrowserRouter>
  );
};

export default App;
