// -- basic library --
import { useCallback, useContext, useEffect } from 'react';

// -- external components --

// -- external data --
import useString from 'hooks/useString/useString';
import { LoginArea, LoginBackGround, LoginBaseArea } from 'components/atoms/StyledLoginTag';
import InputBoxWithIcon from 'components/organisms/InputBoxWithIcon';
import InputComponent from 'components/molecules/InputCompont';
import { Button } from '@blueprintjs/core';
import { useNavigate } from 'react-router-dom';
import { AuthInfo, AuthInfoContext, LoggedInContext } from 'hooks/authContext/authContext';
import { RequestUsersLoginByPlatformPostAPI, usersLoginByPlatformPostAPI } from 'api/users';
import CheckBox from 'components/atoms/CheckBox';
import useBool from 'hooks/useBool/useBool';
import { validateLoginPost } from 'utils/validates';
import AlertDialog from 'components/organisms/AlertDialog';

/**TOPページ**/
const Login = () => {
  // -- local state --
  const [platform_env, setPlatformEnv] = useString();
  const [login_id, setLoginId] = useString();
  const [tenant_id, setTenantId] = useString();
  const [password, setPassword] = useString();
  const [remember, { onSwitch: onRememberSwitch, setBool: setRemember }] = useBool();
  // -- global state --
  const { setAuthInfo, auth_info } = useContext(AuthInfoContext);
  const { logged_in, loading } = useContext(LoggedInContext);
  // 遷移を司るhooks
  const navigate = useNavigate();

  /** auth_infoの情報をもとに入力値を初期化する関数 */
  const initialize = useCallback(() => {
    if (loading) return;
    if (logged_in) return;
    if (auth_info.remember) {
      setPlatformEnv(auth_info.platform_env);
      setTenantId(auth_info.tenant_id);
      setLoginId(auth_info.login_id);
      setRemember(auth_info.remember);
    }
  }, [auth_info, loading, logged_in]);

  const transitionToTop = useCallback(() => {
    navigate('/');
  }, []);

  const onLogin = useCallback(
    (params: AuthInfo) => {
      setAuthInfo(params);
      transitionToTop();
    },
    [transitionToTop]
  );

  /** ログインをする関数 **/
  const loginPost = useCallback(
    async (params: { post_data: RequestUsersLoginByPlatformPostAPI; remember: boolean }) => {
      const { post_data, remember } = params;
      // 入力チェック
      try {
        validateLoginPost(post_data);
      } catch (e) {
        if (e instanceof Error) {
          AlertDialog.show(e.message);
        }
        return;
      }
      const res = await usersLoginByPlatformPostAPI(post_data);
      // 通信に成功したらtopに移動
      if (res) {
        onLogin({
          platform_env: res.platform_env,
          user_id: res.user_id,
          user_name: res.user_name,
          login_id: post_data.login_id,
          remember: remember,
          tenant_id: post_data.tenant_id,
        });
      }
    },
    []
  );

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <LoginBackGround>
      <LoginArea>
        <LoginBaseArea>
          <InputComponent title="platform環境名" required>
            <InputBoxWithIcon value={platform_env} onChange={(e) => setPlatformEnv(e.currentTarget.value)} />
          </InputComponent>
          <InputComponent title="テナントID" required>
            <InputBoxWithIcon value={tenant_id} onChange={(e) => setTenantId(e.currentTarget.value)} />
          </InputComponent>
          <InputComponent title="ログインID" required>
            <InputBoxWithIcon value={login_id} onChange={(e) => setLoginId(e.currentTarget.value)} title="ログインID" />
          </InputComponent>
          <InputComponent title="パスワード" required>
            <InputBoxWithIcon value={password} onChange={(e) => setPassword(e.currentTarget.value)} password={true} />
          </InputComponent>
          <InputComponent title="ログイン情報保持">
            <CheckBox text="保持する" checked={remember} onClick={onRememberSwitch} />
          </InputComponent>
          {/* <FunctionalText text="パスワードを忘れた方はこちら" onClick={} /> */}
        </LoginBaseArea>
        <Button
          text="ログイン"
          onClick={() =>
            loginPost({
              post_data: {
                login_id: login_id,
                password,
                platform_env: platform_env,
                tenant_id: tenant_id,
              },
              remember,
            })
          }
        />
      </LoginArea>
    </LoginBackGround>
  );
};

export default Login;
