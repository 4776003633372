// -- basic library --
import React from 'react';

// -- external components --
import BaseTable, { TableHeaderType } from 'components/molecules/BaseTable';

// -- external types --

// -- external functions --
// -- type declaration --

interface Params {
  bodies: TableTrainingdatasetType[];
  selected_bodies: TableTrainingdatasetType[];
  handleCheckClick: (datas: TableTrainingdatasetType[]) => void;
  footer_option: {
    text: string;
    handleClick: () => void;
    disabled?: boolean;
  };
}

// BaseTableのbodyのtype
export type TableTrainingdatasetType = {
  id: string;
  dataset_name: string;
  number_of_data: number;
  number_of_tags: string;
  created_at: string;
};

// -- external functions --

// -- main component --
const DatasetListTable: React.FC<Params> = (params) => {
  // テーブルの列の情報まとめたデータ
  const headers: TableHeaderType[] = [
    {
      id: 'dataset_id',
      label: 'データセットID',
      sortable: true,
    },
    {
      id: 'dataset_name',
      label: 'データセット名',
      sortable: true,
    },
    {
      id: 'number_of_data',
      label: 'データ数',
      sortable: true,
    },
    {
      id: 'number_of_tags',
      label: '含まれるタグ数',
      sortable: true,
    },
    {
      id: 'created_at',
      label: '作成日',
      sortable: true,
    },
    {
      id: 'detail_element',
      label: '詳細',
    },
  ];

  // -- render part --
  return (
    <BaseTable
      bodies={params.bodies}
      headers={headers}
      table_name="file-processes"
      selected_bodies={params.selected_bodies}
      handleCheckClick={params.handleCheckClick}
      id_abridgement
      search_key="dataset_name"
      footer_option={params.footer_option}
    />
  );
};

// -- styled components --

// -- finally export part --
export default DatasetListTable;
