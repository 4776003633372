// -- basic library --
import React from 'react';
import styled from 'styled-components';
import { colors } from 'utils/colors';

// -- external datas --
import attention_svg from 'assets/attention.svg';

// -- type declaration --
interface Params {
  confirmed: boolean;
}

// -- main component --

const ConfirmText: React.FC<Params> = (params) => {
  // -- render part --
  return (
    <WholeArea title={params.confirmed ? '確認済み' : '未確認'}>
      {params.confirmed ? null : <IconImg src={attention_svg} />}
      <CustomText confirmed={params.confirmed}>{params.confirmed ? '確認済み' : '未確認'}</CustomText>
    </WholeArea>
  );
};

// -- styled components --

const WholeArea = styled.div`
  width: 80px;
  height: 20px;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const IconImg = styled.img`
  width: auto;
  height: 100%;
  margin-right: 5px;
`;

const CustomText = styled.div<{
  confirmed: boolean;
}>`
  display: inline-block;
  font-size: inherit;
  color: ${(params) => (params.confirmed ? colors.main_font_color : colors.icon_dangerous_color)};
`;

// -- finally export part --

export default ConfirmText;
