import { IObject } from 'utils/types';
import APIFetchJson from './base/base';

/*** エンティティ ***/

export const sample_training_data1: TrainingDatasetCart = {
  data_id: 1,
  image_s3_key: 'archive/1/cc59b0d4/81f5/af2e/94d6/c5abbf06bd68.jpg',
  tag_list: ['person', 'car'],
};

/** [GET] /api/training-datas */

export interface RequestTrainingDatasetCartPagingGetAPI {
  limit?: number;
  exclusive_start_key?: number;
}

export interface ResponseTrainingDatasetCartPagingGetAPI {
  items: TrainingDatasetCart[];
  has_next: boolean;
  last_evaluated_data_id: number;
}

export interface TrainingDatasetCart {
  data_id: number;
  image_s3_key: string;
  tag_list: string[];
}

export const trainingDatasetCartPagingGetAPI = async (
  props: RequestTrainingDatasetCartPagingGetAPI
): Promise<ResponseTrainingDatasetCartPagingGetAPI | null> => {
  const d: IObject<string> = {};
  if (typeof props.limit === 'number') d['limit'] = String(props.limit);
  if (typeof props.exclusive_start_key === 'number') d['exclusive_start_key'] = String(props.exclusive_start_key);
  const query_params = new URLSearchParams(d);
  const res = await APIFetchJson<ResponseTrainingDatasetCartPagingGetAPI>({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    path: `/api/training-dataset-cart?${query_params}`,
    method: 'GET',
    mock_res: {
      items: new Array(30).fill(null).map((_, i) => {
        const d = { ...sample_training_data1 };
        d.data_id = i;
        return d;
      }),
      has_next: true,
      last_evaluated_data_id: 1,
    },
  });
  return res;
};

/** [POST] /api/training-dataset/cart */

export interface RequestTrainingDatasetCartPostAPI {
  tag_list?: string[];
  dataset_id?: string;
}

export interface ResponseTrainingDatasetCartPostAPI {
  succeed: boolean;
}

export const trainingDatasetCartPostAPI = async (
  props: RequestTrainingDatasetCartPostAPI
): Promise<ResponseTrainingDatasetCartPostAPI | null> => {
  const res = await APIFetchJson<ResponseTrainingDatasetCartPostAPI>({
    path: '/api/training-dataset/cart',
    method: 'POST',
    body: JSON.stringify(props),
    mock_res: {
      succeed: true,
    },
  });
  return res;
};

/** [POST] /api/training-dataset/cart/reset */

export interface ResponseResetTrainingDatasetCartDeleteAPI {
  succeed: boolean;
}

export const resetTrainingDatasetCartDeleteAPI =
  async (): Promise<ResponseResetTrainingDatasetCartDeleteAPI | null> => {
    const res = await APIFetchJson<ResponseResetTrainingDatasetCartDeleteAPI>({
      path: `/api/training-dataset/cart`,
      method: 'DELETE',
      mock_res: {
        succeed: true,
      },
    });
    return res;
  };

/** [DELETE] /api/training-dataset/cart/{data_id} */

export interface RequestTrainingDatasetCartDeleteAPI {
  data_id: number;
}

export interface ResponseTrainingDatasetCartDeleteAPI {
  succeed: boolean;
}

export const trainingDatasetCartDeleteAPI = async (
  props: RequestTrainingDatasetCartDeleteAPI
): Promise<ResponseTrainingDatasetCartDeleteAPI | null> => {
  const res = await APIFetchJson<ResponseTrainingDatasetCartDeleteAPI>({
    path: `/api/training-dataset/cart`,
    method: 'DELETE',
    body: JSON.stringify(props),
    mock_res: {
      succeed: true,
    },
  });
  return res;
};
