import { Card, CardProps, Elevation } from '@blueprintjs/core';

export interface RoundedCardProps extends CardProps {
  children?: React.ReactNode;
}
/** 丸みを帯びたカード**/
const RoundedCard = (props: RoundedCardProps) => {
  const newstyle: React.CSSProperties = {
    ...props.style,
    width: 320,
    height: 320,
    padding: 10,
    margin: 10,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
  };

  return (
    <Card style={newstyle} elevation={Elevation.THREE}>
      {props.children}
    </Card>
  );
};

export default RoundedCard;
