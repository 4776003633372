import styled from 'styled-components';
import { colors } from 'utils/colors';
import styles from 'utils/styles';

/**
 * サイドメニューのメニュー1つ
 */
export interface MenuItemProps {
  id: string;
  text: string;
  img_src?: string;
  selected: boolean;
  onClick: (id: string) => void;
}
const MenuItem = (props: MenuItemProps) => {
  return (
    <StyledMenuItem
      onClick={() => props.onClick(props.id)}
      id={props.id}
      selected={props.selected}
      style={{ cursor: 'pointer' }}
    >
      {props.img_src && <StyledMenuItemImg src={props.img_src} />}
      <StyledMenuItemText>{props.text}</StyledMenuItemText>
    </StyledMenuItem>
  );
};

const StyledMenuItem = styled.div<{
  selected: boolean;
}>`
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  background-color: ${(p) => (p.selected ? colors.component_main_color : colors.black)};
  font-family: 'Noto Sans JP';
  font-weight: bold;
`;

const StyledMenuItemImg = styled.img`
  width: 28px;
  height: 28px;
`;

const StyledMenuItemText = styled.div`
  margin-top: 4px;
  font-size: ${styles.super_small_text_size};
  color: ${colors.component_text_color};
`;

export default MenuItem;
