import React from 'react';
import TrainingDataThumbnailImage from 'components/organisms/TrainingDataThumbnailImage';
import RoundedCard from 'components/atoms/RoundedCard';
import DeleteIcon from 'components/atoms/DeleteIcon';

/** 学習データ一覧で表示するデータを表示するカード **/
interface FileCardDisplayingTrainingDataProps {
  data_id: number;
  image_s3_key: string;
  tag_list: string[];
  deleteTrainingData?: () => Promise<void>;
}
export default function FileCardDisplayingTrainingData(props: FileCardDisplayingTrainingDataProps) {
  return (
    <RoundedCard>
      {props.deleteTrainingData && (
        <div
          style={{
            display: 'flex',
            height: 20,
            justifyContent: 'flex-end',
          }}
        >
          <DeleteIcon onClick={props.deleteTrainingData} size={20} color="inherit" />
        </div>
      )}
      <div style={{ height: 240, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <TrainingDataThumbnailImage image_s3_key={props.image_s3_key} style={{ maxHeight: 170 }} />
      </div>

      <div
        style={{
          overflow: 'auto',
          height: 80,
          margin: '5px 0',
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}
      >
        {props.tag_list.map((tag) => {
          return (
            <p key={tag} style={{ margin: 0, width: '33%' }}>
              {tag}
            </p>
          );
        })}
      </div>

      <div style={{ flexGrow: 1, paddingTop: 10, borderTop: '1px solid rgb(145, 145, 145)' }}>No.{props.data_id}</div>
    </RoundedCard>
  );
}
