// -- basic library --
import { useCallback } from 'react';
import styles from 'utils/styles';
import styled from 'styled-components';
import { colors } from 'utils/colors';

// -- external images --
import sidemenu_IDEA_logo from 'assets/sidemenu_IDEA_logo.png';

// -- external functions --
import MenuItem from 'components/molecules/MenuItem';
import { useNavigate } from 'react-router-dom';
import useString from 'hooks/useString/useString';

// urlパスからmenu_idを取得する
const getMenuId = (): string => {
  // 現在のURLパスから機能カテゴリを取得します。
  const s = window.location.pathname.split('/');
  return s.length > 1 ? s[1] : '';
};

// --
/**
 * 左サイドメニュー
 */
const GnSideMenu = () => {
  const navigate = useNavigate();
  const [menu_id, setMenuId] = useString(getMenuId());
  // ロゴをクリックした時の関数
  const onLogoClick = useCallback(() => {
    navigate('/');
  }, []);
  const onMenuClick = useCallback((new_id: string) => {
    setMenuId(new_id);
    navigate(`/${new_id}`);
  }, []);
  return (
    <MenuContainer>
      <MenuHeader>
        <Logo src={sidemenu_IDEA_logo} onClick={onLogoClick} />
      </MenuHeader>
      <MenuWrapper>
        <MenuItem id="" text="トップ" selected={menu_id === '' ? true : false} onClick={onMenuClick} />
        <MenuItem
          id="dataset"
          text="データセット作成"
          selected={menu_id === 'dataset' ? true : false}
          onClick={onMenuClick}
        />
        <MenuItem
          id="datasetList"
          text="データセット一覧"
          selected={menu_id === 'datasetList' ? true : false}
          onClick={onMenuClick}
        />
      </MenuWrapper>
    </MenuContainer>
  );
};

export default GnSideMenu;

const MenuContainer = styled.div`
  min-height: calc(100vh - ${styles.footer_height});
  height: calc(100% - ${styles.footer_height});
  width: ${styles.sidemenu_width};
  transition: width 0.3s, margin 0.3s;
  transition-timing-function: ease-out;
  background-color: ${colors.black};
  position: relative;
  overflow: hidden;
`;

const MenuHeader = styled.div`
  height: ${styles.topmenu_height};
  widht: 100%;
  padding: 15px 0px;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MenuWrapper = styled.div`
  height: calc(100% - ${styles.topmenu_height} - 30px * 2);
  width: 100%;
  box-shadow: none;
  padding: 0;
`;

const Logo = styled.img`
  width: auto;
  height: 100%;
`;
