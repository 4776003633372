// -- basic library --

// -- http connection library --

// -- external components --
import GnDialog, { GnDialogProps } from 'components/atoms/GnDialog';
import InputComponent from 'components/molecules/InputCompont';
import InputBox from 'components/atoms/InputBox';
import useString from 'hooks/useString/useString';
import { Footer } from 'components/atoms/ContentsArea';
import RoundedButton from 'components/atoms/RoundedButton';
import { trainingDatasetPostAPI } from 'api/training-datasets';
import { Weaken } from 'utils/types';
import { validateDatasetName } from 'utils/validates';
import AlertDialog from 'components/organisms/AlertDialog';

// -- external datas --

// -- external functions --

// -- external types --
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface CreateDatasetDialogProps extends Weaken<GnDialogProps, 'onClose'> {
  onClose: (cancel?: boolean) => void;
}

// -- main component --
const CreateDatasetDialog = (props: CreateDatasetDialogProps) => {
  const [dataset_name, setDatasetName] = useString();
  // 完了した時の関数
  const onFinish = async () => {
    // バリデーション
    try {
      validateDatasetName(dataset_name);
    } catch (e) {
      if (e instanceof Error) {
        AlertDialog.show(e.message);
      }
      return;
    }
    // データセット名を元にデータセットを作成(裏側でtenant_idに紐づくカートテーブルから作成する)
    const res = await trainingDatasetPostAPI({
      dataset_name: dataset_name,
    });
    if (res) {
      AlertDialog.show(
        <div>
          データセット作成処理を開始しました。
          <br />
          数分後にデータセット一覧画面をご確認ください。
          <br />
          データ量によっては10分以上かかる場合があります。
        </div>
      );
      props.onClose(false);
    }
  };
  // -- render part --
  return (
    <GnDialog
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      isOpen={props.isOpen}
      onClose={() => props.onClose(true)}
      title="データセット作成"
      canOutsideClickClose={false}
    >
      <InputComponent title="データセット名" required>
        <InputBox value={dataset_name} onChange={(e) => setDatasetName(e.currentTarget.value)} title="データセット名" />
      </InputComponent>
      <Footer>
        <RoundedButton onClick={() => props.onClose(true)} text="キャンセル" is_white={true} is_margin_right={true} />
        <RoundedButton onClick={onFinish} text_type="CREATE" />
      </Footer>
    </GnDialog>
  );
};

// -- finally export part --

export default CreateDatasetDialog;
