// -- basic library --
import React from 'react';
import styled from 'styled-components';
import styles from 'utils/styles';
import { colors } from 'utils/colors';

// -- external datas --
import check_svg from 'assets/check.svg';
import pause_svg from 'assets/pause.svg';

// -- type declaration --
interface Params {
  state: 'ACTIVE' | 'PAUSE';
}

// -- main component --

const StateText: React.FC<Params> = (params) => {
  // -- render part --
  return (
    <WholeArea>
      <IconImg src={params.state === 'ACTIVE' ? check_svg : pause_svg} />
      <CustomText state={params.state}>{params.state === 'ACTIVE' ? 'ACTIVE' : 'PAUSE'}</CustomText>
    </WholeArea>
  );
};

// -- styled components --

const WholeArea = styled.div`
  width: 60px;
  height: 15px;
  display: flex;
  align-items: center;
  white-space: nowrap；;
`;

const IconImg = styled.img`
  width: auto;
  height: 100%;
`;

const CustomText = styled.div<{
  state: 'ACTIVE' | 'PAUSE';
}>`
  margin-left: 5px;
  display: inline-block;
  font-weight: bold;
  font-size: ${styles.small_text_size};
  color: ${(params) => (params.state === 'ACTIVE' ? colors.icon_safe_color : colors.icon_dangerous_color)};
`;

// -- finally export part --

export default StateText;
