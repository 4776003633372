// -- basic library --
import styles from 'utils/styles';
import React from 'react';
import styled from 'styled-components';

// -- external components --
import { colors } from 'utils/colors';

// -- external datas --

export interface InputBoxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  inputRef?: React.RefObject<HTMLInputElement>;
  is_error?: boolean;
}
const InputBox = (props: InputBoxProps) => {
  return (
    <StyledInput
      {...props}
      ref={props.inputRef}
      onWheel={props.type === 'number' ? (e) => e.currentTarget.blur() : undefined}
    />
  );
};

export default InputBox;

// -- styled components --

const StyledInput = styled.input<{
  is_error?: boolean;
}>`
  width: 100%;
  padding: ${styles.input_box_padding};
  height: ${styles.input_component_height};
  box-sizing: border-box;
  border: 1px solid ${colors.component_small_border_color};
  border-radius: ${styles.border_radius};
  outline: none;
  font-family: inherit;
  color: ${colors.main_font_color};
  background-color: ${(params) => (params.is_error ? colors.error_color : colors.white)};
  &:focus {
    border-color: ${colors.component_main_color};
  }
  &:disabled {
    opacity: 1;
    background-color: ${colors.disabled_background_color};
    cursor: not-allowed;
  }
  &:placeholder {
    color: ${colors.placeholder_color};
  }
`;
