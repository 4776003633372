// -- basic library --
import styles from 'utils/styles';
import React from 'react';
import styled from 'styled-components';

// -- external components --
import InputBox, { InputBoxProps } from 'components/atoms/InputBox';
import CheckBox, { CheckBoxProps } from 'components/atoms/CheckBox';

// -- external datas --

/***
 * テーブル内の検索コンポーネント
 *
 * ***/
interface SearchInputBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  inputBoxProps?: InputBoxProps;
  checkBoxProps?: CheckBoxProps;
}
export default class SearchInputBox extends React.Component<SearchInputBoxProps> {
  render() {
    return (
      <ParentDiv {...this.props}>
        <InputBox {...this.props.inputBoxProps} />
        {this.props.checkBoxProps && (
          <CheckBox {...this.props.checkBoxProps} style={{ marginLeft: styles.interval_margin }} />
        )}
      </ParentDiv>
    );
  }
}

// -- styled components --

const ParentDiv = styled.div`
  width: 450px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;
