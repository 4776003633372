/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// -- basic library --
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// -- external components --
import styles, { table_cell_button_width } from 'utils/styles';
import IconButton from 'components/molecules/IconButton';
import SortIconForTable from 'components/molecules/SortIconForTable';
import PageTurningComponentForTable from 'components/molecules/PageTurningComponentForTable';
import DisplayDataForTable from './DisplayDataForTable';
import { Table, Tbody, Td, Th, Thead, Tr, CheckBoxArea } from 'components/atoms/GnTable';
import FunctionalText from 'components/atoms/FunctionalText';
import CheckBox from 'components/atoms/CheckBox';
import SearchInputBox from './SearchInputBox';
import SelectBoxOrder, { ADOrder } from './SelectBoxOrder';

// -- external datas --
import filter_off_svg from 'assets/filter_off.svg';

// -- external functions --
import { getPageNumberFromSessionStorage, setPageNumberFromSessionStorage } from 'utils/sessionStorageFunctions';
import getTablePageDisplayNumber from 'utils/getTablePageDisplayNumber';
import { pc, sp, tab } from 'utils/media';
import { isValueUrl } from 'utils/baseTableFunctions';

// -- type declaration --
export interface ValueUrl {
  value: string;
  url: string;
}

export interface TableHeaderType {
  id: string;
  width?: string;
  label: string;
  style?: React.CSSProperties;
  sortable?: boolean;
  filtered?: boolean;
  useTableButtonStyles?: boolean;
  useDoubleTableButtonStyles?: boolean;
}

export interface TableStreamType {
  stream_id: string;
  stream_name: string;
  is_video: boolean;
}

export interface TableBodyType {
  id: string;
  [key: string]: any;
}

export interface TableSwitchDataType {
  key_name: string;
  display_datas: {
    id: string;
    text: string;
  }[];
}

export interface BaseTableParams {
  headers: TableHeaderType[];
  table_name: string; // テーブルのpageNumberデータを格納する時に使う。
  id_abridgement?: boolean;
  bodies: TableBodyType[];
  selected_bodies: TableBodyType[];
  handleCheckClick: (seleted_bodies: any[]) => void;
  footer_option?: {
    text: string;
    handleClick: () => void;
    disabled?: boolean;
  };
  handleAddDatas?: () => void; //データを追加する関数
  has_next?: boolean;
  required?: boolean;
  bodies_per_page?: number;
  hide_toparea?: boolean; // ページ番号の表示を止める(この時全てのデータが1ページに表示される)
  hide_name_search?: boolean; // 検索ボタンを表示するか
  switching_datas?: TableSwitchDataType;
  checkbox_abridgement?: boolean;
  disabled_padding_lefts?: { [key: string]: boolean }; // テーブル内のセルにはdefaultでpadding_leftをかけている。これを無効にしたいセルのkeyに対してtrueをつける。
  style?: React.CSSProperties;
  head_sticky?: boolean; // テーブルのヘッダーをスクロールしても固定にするか
  side_sticky?: boolean; // テーブルのヘッダーをスクロールしても固定にするか
  search_key?: string; // 検索させるkeyの名前(存在しないkeyなら検索しない)
  orderProps?: {
    order: ADOrder;
    setOrder: (order: ADOrder) => void;
  }; // 取得するデータを昇順にするか、降順にするか。ページ番号をリセットするために使用
}

// table_nameを安全に取得する
const getTableName = (table_name: string) => {
  return table_name || 'anonymous';
};

// -- main component --

const BaseTable: React.FC<BaseTableParams> = (params) => {
  // 一ページに表示させるデータの個数(トップエリアを隠す場合は最大の整数)
  const bodies_per_page = params.hide_toparea ? Number.MAX_SAFE_INTEGER : params.bodies_per_page || 10;
  // テーブルのヘッダーをスクロール時に固定するか(デフォルトでtrue)
  const head_sticky = params.head_sticky === undefined ? true : params.head_sticky;
  // テーブルのサイドをスクロール時に固定するか(デフォルトでtrue)
  const side_sticky = params.side_sticky === undefined ? true : params.side_sticky;
  // ページ切り替えボタンの数字の表示数
  // 例えば5ならば <- 1 2 3 4 5 -> となる
  const page_display_number = 5;
  // テーブル名(取得できなければ'anonymous')
  const table_name = getTableName(params.table_name);

  // -- local states --
  const [body_checkboxes, setBodyCheckBoxes] = useState<{
    [key: string]: boolean;
  }>({});
  const [sort_key_to_reverse, setSortKeyToReverse] = useState<{
    key: string;
    reverse: boolean;
  } | null>(null);
  const [selected_page_number, setSelectedPageNumber] = useState<number>(1);
  const [perfect_search, setPerfectSearch] = useState<boolean>(false);
  // 検索名
  const [search_name, setSearchName] = useState<string>('');
  // 検索filter後のparams.bodies
  const [searched_bodies, setSearchedBodies] = useState<TableBodyType[]>(params.bodies);

  // テーブルのページが更新された時に安全に反映する関数
  const handleTablePageChange = (pageNumber: number) => {
    let pn: number = pageNumber;
    const max_page_number = Math.ceil(searched_bodies.length / bodies_per_page);
    // 最大をデータの長さ分にする
    pn = Math.min(pn, max_page_number);
    // 最小を1にする
    pn = Math.max(pn, 1);
    setSelectedPageNumber(pn);
    // window.sessionStorageの指定のtable_nameのpageNumberを更新する。
    setPageNumberFromSessionStorage(table_name, pn);
  };

  // テーブルのヘッダーがチェックがどうか判断
  const checkHeaderChecked = (selected_bodies: TableBodyType[]) => {
    const one_page_bodies = searched_bodies.slice(
      (selected_page_number - 1) * bodies_per_page,
      selected_page_number * bodies_per_page
    );
    let required_true_lenth = one_page_bodies.length;
    for (let i = 0; i < one_page_bodies.length; i++) {
      if (
        selected_bodies.some((selected_body) => {
          return selected_body.id === one_page_bodies[i].id;
        })
      ) {
        required_true_lenth -= 1;
      }
    }
    return required_true_lenth <= 0 && one_page_bodies.length > 0 ? true : false;
  };
  // ヘッダーの表示するデータのidを取得
  const getIdsFromHeaders = (headers: TableHeaderType[], id_abridgement?: boolean) => {
    const return_ids: string[] = [];
    for (let i = 0; i < headers.length; i++) {
      if (id_abridgement && headers[i].id === 'id') continue;
      return_ids.push(headers[i].id);
    }
    return return_ids;
  };

  // ボディーに表示するデータを得る関数
  const getDisplayBody = (body: TableBodyType, ids: string[]) => {
    const return_body: {
      [key: string]: any;
    } = {};
    // bodyの情報をheaderの並び順に変える && headerにないものはskip
    for (let i = 0; i < ids.length; i++) {
      const key = ids[i];
      return_body[key] = body[key] || '';
    }
    return return_body;
  };
  // bodiesのデータからidの配列を生成する関数
  const getBodiesIds = (bodies: TableBodyType[]) => {
    const bodies_ids = bodies.map((body) => {
      return body.id;
    });
    return bodies_ids;
  };

  // 2つの配列を重複を消してマージする
  const mergeArray = (bodies1: TableBodyType[], bodies2: TableBodyType[]) => {
    const return_bodies: TableBodyType[] = [];
    const tmp_bodies = [...bodies1, ...bodies2];
    for (let i = 0; i < tmp_bodies.length; i++) {
      const id = tmp_bodies[i].id;
      if (getBodiesIds(return_bodies).indexOf(id) !== -1) continue;
      return_bodies.push(tmp_bodies[i]);
    }
    return return_bodies;
  };

  // 渡されたselected_datasのidに基づいてチェックボックスのチェック状態を変える
  const updateBodyCheckboxes = (selected_bodies: TableBodyType[]) => {
    //1ページ内のbodies
    const one_page_bodies = searched_bodies.slice(
      (selected_page_number - 1) * bodies_per_page,
      selected_page_number * bodies_per_page
    );
    const new_body_checkboxes: { [key: string]: boolean } = {};
    for (let i = 0; i < one_page_bodies.length; i++) {
      const id = one_page_bodies[i].id;
      new_body_checkboxes[id] = false;
    }
    for (let i = 0; i < selected_bodies.length; i++) {
      const id = selected_bodies[i].id;
      new_body_checkboxes[id] = true;
    }
    setBodyCheckBoxes(new_body_checkboxes);
  };

  // テーブルをクリックした時の挙動
  const handleTableTrClick = (body: TableBodyType, selected_bodies: TableBodyType[]) => {
    // チェックボックスの動きを省略する
    if (params.checkbox_abridgement) {
      return;
    }
    const id = body.id;
    const selected_bodies_ids = getBodiesIds(selected_bodies);
    const selected_index = selected_bodies_ids.indexOf(id);
    let new_selected_bodies: TableBodyType[] = [];

    if (selected_index === -1) {
      new_selected_bodies = new_selected_bodies.concat(selected_bodies, body);
    } else if (selected_index === 0) {
      new_selected_bodies = new_selected_bodies.concat(selected_bodies.slice(1));
    } else if (selected_index === selected_bodies_ids.length - 1) {
      new_selected_bodies = new_selected_bodies.concat(selected_bodies.slice(0, -1));
    } else if (selected_index > 0) {
      new_selected_bodies = new_selected_bodies.concat(
        selected_bodies.slice(0, selected_index),
        selected_bodies.slice(selected_index + 1)
      );
    }
    params.handleCheckClick(new_selected_bodies);
    updateBodyCheckboxes(new_selected_bodies);
  };

  // ヘッダーのチェックボックスの動き
  const handleTableHeaderCheckboxClick = (checked: boolean, selected_bodies: TableBodyType[]) => {
    // チェックボックスの動きを省略する
    if (params.checkbox_abridgement) {
      return;
    }
    // 1ページのbodies
    const one_page_bodies = searched_bodies.slice(
      (selected_page_number - 1) * bodies_per_page,
      selected_page_number * bodies_per_page
    );
    if (!checked) {
      const merged_bodies = mergeArray(selected_bodies, one_page_bodies);
      params.handleCheckClick(merged_bodies);
      updateBodyCheckboxes(merged_bodies);
      return;
    }
    const new_selected_bodies: TableBodyType[] = [];
    const bodies_ids = getBodiesIds(one_page_bodies);
    for (let i = 0; i < selected_bodies.length; i++) {
      const selected_id_index = bodies_ids.indexOf(selected_bodies[i].id);
      if (selected_id_index !== -1) continue;
      new_selected_bodies.push(selected_bodies[i]);
    }
    params.handleCheckClick(new_selected_bodies);
    updateBodyCheckboxes(new_selected_bodies);
  };

  // a-Z順にソートする
  const getsortedDatas = (bodies: TableBodyType[], sort_key_to_reverse: { key: string; reverse: boolean } | null) => {
    if (sort_key_to_reverse === null) {
      return bodies;
    }
    const old_bodies = bodies.slice();
    const sorted_bodies: TableBodyType[] = old_bodies.sort((a, b) => {
      const key = sort_key_to_reverse.key;
      // objectのdataだった時の暫定対応
      if (sort_key_to_reverse.reverse) {
        if (key === 'changeable_status') {
          return a[key]['status'] < b[key]['status'] ? -1 : 1;
        }
        if (isValueUrl(a)) {
          return a[key]['value'] < b[key]['value'] ? -1 : 1;
        }

        return a[key] < b[key] ? -1 : 1;
      } else {
        if (key === 'changeable_status') {
          return a[key]['status'] < b[key]['status'] ? 1 : -1;
        }
        if (isValueUrl(a)) {
          return a[key]['value'] < b[key]['value'] ? 1 : -1;
        }
        return a[key] < b[key] ? 1 : -1;
      }
    });

    return sorted_bodies;
  };

  // search_nameを変更した時の挙動
  const handleSearchNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchName(e.currentTarget.value);
    onSearchChange(e.currentTarget.value);
  };

  // perfect_searchを変更した時の挙動
  const handlePerfectSearchChange = (bol: boolean) => {
    setPerfectSearch(bol);
    onSearchChange(search_name, bol);
  };

  // 検索名 or 完全一致チェックが変更された時の挙動
  const onSearchChange = (value: string, perfect?: boolean) => {
    const sk = params.search_key;
    // skがない時は何もしない
    if (sk === undefined || sk.length <= 0) return;
    const ps = perfect === undefined ? perfect_search : perfect;
    // 検索ワードに引っかかるsearched_bodies
    const searched_bodies = params.bodies.filter((body) => {
      const bn = body[sk];
      // key要素が存在しない or 文字列でなければ、問答無用で返す
      if (typeof bn !== 'string') return true;
      if (value.length <= 0) return true;
      if (ps) {
        return bn === value;
      }
      const lower_search_name = value.toLowerCase();
      return bn.toLowerCase().indexOf(lower_search_name) > -1;
    });
    // searched_datasを更新
    setSearchedBodies(searched_bodies);
    // 検索後のページ番号を1に戻す
    handleTablePageChange(1);

    // searched_bodiesのidのリスト
    const sids = getBodiesIds(searched_bodies);
    // sids内に存在するものだけselected_bodiesに挿入する
    const new_selected_bodies = params.selected_bodies.filter((body) => {
      return sids.includes(body.id);
    });
    // selected_bodiesとチェックを更新する
    updateBodyCheckboxes(new_selected_bodies);
    params.handleCheckClick(new_selected_bodies);
  };

  // ソートアイコンを押した時の挙動
  const handleSortClick = (sort_key: string, reverse: boolean) => {
    const new_sort_key_to_reverse = { key: sort_key, reverse: reverse };
    setSortKeyToReverse(new_sort_key_to_reverse);
  };

  // ソート解除ボタンを押した時の挙動
  const handleSortReleaseClick = () => {
    setSortKeyToReverse(null);
  };

  // -- onload function --
  useEffect(() => {
    setBodyCheckBoxes({});
    setSearchedBodies(params.bodies);
    setSearchName('');
  }, [params.bodies]); /* eslint-disable-line */

  useEffect(() => {
    // テーブルのpageNumber
    const pageNumber: number = getPageNumberFromSessionStorage(table_name);
    handleTablePageChange(pageNumber);
  }, [searched_bodies]); /* eslint-disable-line */

  // -- render part --
  return (
    <WholeArea {...params}>
      {/* {getSwitchingDatas(switched_bodies, params.switching_datas) ? (
        <TextSwitchingDatas
          display_datas={getSwitchingDatas(params.bodies, params.switching_datas)}
          handleClick={handleSwitchingClick}
          selected_data_id={switching_selected_id}
          style={{ marginBottom: styles.interval_narrow_margin }}
        />
      ) : null} */}
      {/*昇順/降順を選択して、データを再取得するコンポーネント*/}
      {params.orderProps && (
        <SubTopArea>
          <div style={{ width: '100%', flexBasis: '100%' }}></div>
          <SelectBoxOrder
            value={params.orderProps.order}
            setValue={params.orderProps.setOrder}
            onFinished={() => handleTablePageChange(1)}
          />
        </SubTopArea>
      )}
      {params.hide_toparea ? null : (
        <TopArea>
          <div style={{ width: '100%', flexBasis: '100%' }}>
            {params.hide_name_search ? null : (
              <SearchInputBox
                inputBoxProps={{
                  value: search_name,
                  onChange: handleSearchNameChange,
                  placeholder: '名前を検索します',
                }}
                checkBoxProps={{
                  checked: perfect_search,
                  text: '完全一致',
                  onClick: () => handlePerfectSearchChange(!perfect_search),
                }}
              />
            )}
          </div>

          <div style={{ display: 'flex', alignItems: 'center', width: 'auto' }}>
            <PageNumberText>
              {getTablePageDisplayNumber(selected_page_number, searched_bodies, bodies_per_page, params.has_next)}
            </PageNumberText>
            <PageTurningComponentForTable
              handleAddDatas={params.handleAddDatas}
              has_next={params.has_next}
              handleClick={(num: number) => {
                handleTablePageChange(num);
              }}
              selected_page_number={selected_page_number}
              bodies_per_page={bodies_per_page}
              bodies={searched_bodies}
              page_display_number={page_display_number}
            />
          </div>
        </TopArea>
      )}
      {/* テーブル */}
      <TableArea>
        <Table sticky={head_sticky || side_sticky}>
          <Thead>
            <tr>
              {/* テーブルヘッダーのチェックボックス */}
              {params.checkbox_abridgement ? null : (
                <Th
                  style={{ width: 35 }}
                  onClick={() =>
                    handleTableHeaderCheckboxClick(checkHeaderChecked(params.selected_bodies), params.selected_bodies)
                  }
                  head_sticky={head_sticky}
                  side_sticky={side_sticky}
                >
                  <CheckBoxArea>
                    <CheckBox checked={checkHeaderChecked(params.selected_bodies)} />
                  </CheckBoxArea>
                </Th>
              )}
              {/* テーブルヘッダー */}
              {params.headers.map((header, index) => {
                const h = { ...header };
                if (h.useTableButtonStyles) {
                  h.style = {
                    ...h.style,
                    width: table_cell_button_width,
                  };
                }
                if (h.useDoubleTableButtonStyles) {
                  h.style = {
                    ...h.style,
                    width: `calc(${styles.super_small_button_width} * 2 + ${styles.interval_narrow_margin} * 3)`,
                  };
                }
                return (
                  <Th
                    key={index}
                    style={{
                      paddingLeft: styles.interval_narrow_margin,
                      width: h.width,
                      ...h.style,
                    }}
                    head_sticky={head_sticky}
                    side_sticky={side_sticky}
                  >
                    <ThChildrensArea>
                      {header.label}
                      {header.sortable ? (
                        <SortIconForTable
                          handleClick1={() => handleSortClick(header.id, false)}
                          handleClick2={() => handleSortClick(header.id, true)}
                        />
                      ) : null}
                      {header.filtered ? (
                        <IconButton
                          img_src={filter_off_svg}
                          onClick={handleSortReleaseClick}
                          style={{ width: 20, height: 20, background: 'none' }}
                        />
                      ) : null}
                    </ThChildrensArea>
                  </Th>
                );
              })}
            </tr>
          </Thead>

          {/* テーブルボディー */}
          <Tbody>
            {/* ボディーデータをソートした後に、一ページ分のデータに切り分ける */}
            {getsortedDatas(searched_bodies, sort_key_to_reverse)
              .slice((selected_page_number - 1) * bodies_per_page, selected_page_number * bodies_per_page)
              .map((body, index1) => {
                const body_id = body.id;
                const header_ids = getIdsFromHeaders(params.headers, params.id_abridgement);
                const new_body = getDisplayBody(body, header_ids);
                return (
                  <Tr
                    key={index1}
                    checked={body_checkboxes[body_id] ? body_checkboxes[body_id] : false}
                    onClick={() => handleTableTrClick(body, params.selected_bodies)}
                  >
                    {/* テーブルボディーのチェックボックス */}
                    {params.checkbox_abridgement ? null : (
                      <Td
                        style={{ width: 35 }}
                        last_row={
                          (index1 + 1) % bodies_per_page === 0 || index1 + 1 === searched_bodies.length ? true : false
                        }
                        side_sticky={side_sticky}
                      >
                        <CheckBoxArea>
                          <CheckBox checked={body_checkboxes[body_id] ? body_checkboxes[body_id] : false} />
                        </CheckBoxArea>
                      </Td>
                    )}
                    {/* テーブルボディーのデータ */}
                    {Object.entries(new_body).map(([key, value]) => {
                      return (
                        <Td
                          key={key}
                          last_row={
                            (index1 + 1) % bodies_per_page === 0 || index1 + 1 === searched_bodies.length ? true : false
                          }
                          style={{ paddingLeft: styles.interval_narrow_margin }}
                          side_sticky={side_sticky}
                        >
                          <DisplayDataForTable key_name={key} value={value} />
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableArea>

      {/* フッター */}
      {params.footer_option ? (
        <FooterArea>
          <OptionFunctionArea>
            <FunctionalText
              text={params.footer_option.text}
              onClick={params.footer_option.disabled ? undefined : params.footer_option.handleClick}
              disabled={params.footer_option.disabled}
            />
          </OptionFunctionArea>
        </FooterArea>
      ) : null}
    </WholeArea>
  );
};

// -- styled components --

const WholeArea = styled.div`
  width: 100%;
  height: 100%;
  display: block;
`;

const TableArea = styled.div`
  max-width: 100%;
  width: 100%;
  height: calc(100% - 25px - 25px - ${styles.interval_narrow_margin} * 2);
  border-radius: ${styles.table_radius_px};
  max-height: 500px;
  overflow: scroll;
`;

const SubTopArea = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: ${styles.interval_x_narrow_margin};
`;

const TopArea = styled.div`
  display: flex;
  ${pc`
    justify-content: space-between;
  `}
  ${tab`
    justify-content: flex-end;
    flex-wrap: wrap;
  `}
  ${sp`
    justify-content: flex-end;
    flex-wrap: wrap;
  `}
  align-items: center;
  width: 100%;
  margin-bottom: ${styles.interval_narrow_margin};
`;

const FooterArea = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 25px;
  margin-top: ${styles.interval_narrow_margin};
`;

const ThChildrensArea = styled.div`
  display: flex;
  align-items: center;
`;

const OptionFunctionArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
`;

const PageNumberText = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 150px;
  min-width: 150px;
  height: 100%;
  margin-right: ${styles.interval_narrow_margin};
`;

// -- finally export part --

export default BaseTable;
