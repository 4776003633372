// -- basic library --
import { useCallback } from 'react';

// -- external components --
// import { GridWrapper } from 'components/atoms/StyledTag';
import { Button } from '@blueprintjs/core';

// -- external data --
import { trainingDatasImageUploadPostAPI, trainingDatasImageUploadPutAPI } from 'api/training-datas';
import useFile, { FileType } from 'hooks/useFiles/useFiles';
import FileBox from 'components/molecules/FileBox';

/**TOPページ**/
export default function Tops() {
  const [file, setFile] = useFile();
  const getPresignedUrl = useCallback(async (file?: FileType) => {
    if (!file) {
      return;
    }
    const res = await trainingDatasImageUploadPostAPI({
      file_name: file.file_name,
    });
    if (res) {
      const upload_res = await trainingDatasImageUploadPutAPI({
        upload_url: res.url,
        file: file.file,
      });
    }
  }, []);
  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files !== null) {
      const new_stream_data_files = [];
      for (let i = 0; i < e.currentTarget.files.length; i++) {
        const file = e.currentTarget.files[i];
        const file_name = file.name || '';
        new_stream_data_files.push({
          file: file,
          file_name: file_name,
        });
      }
      setFile(new_stream_data_files[0]);
    }
  }, []);
  return (
    <div>
      {/* {sample_data.map((sd, i) => {
        return (
          <Suspense fallback={<Spinner />} key={i}>
            <GnRoundedCard key={i}>
              <h3>{sd.name}</h3>
              <Button onClick={loadTrainingDatasImage} text="samples取得" />
              <GNImg src={sd.src} alt="アノテーション画像" />
            </GnRoundedCard>
          </Suspense>
        );
      })} */}
      <FileBox button_text="ファイル選択" accept="*/*" onChange={onChange} file_name={file?.file_name || ''} />
      <Button text="s3-put-test" onClick={() => getPresignedUrl(file)} />
    </div>
  );
}
