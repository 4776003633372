import APIFetchJson from './base/base';

/*** エンティティ ***/
export interface ResponseUsersLoginPostAPI {
  user_name: string;
  user_id: string;
  platform_env: string;
}
/**
 * [POST] /api/users/login
 * ログインをする関数(platformのドメインに対してログインする)
 * */
export interface RequestUsersLoginByPlatformPostAPI {
  login_id: string;
  password: string;
  tenant_id: string;
  platform_env: string;
}

export const usersLoginByPlatformPostAPI = async (
  args: RequestUsersLoginByPlatformPostAPI
): Promise<ResponseUsersLoginPostAPI | null> => {
  const res = await APIFetchJson<ResponseUsersLoginPostAPI>({
    path: `/api/users/login`,
    method: 'POST',
    body: JSON.stringify(args),
    mock_res: {
      user_id: 's',
      user_name: 'b',
      platform_env: 'hase',
    },
  });
  return res;
};
