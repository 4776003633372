import React, { useCallback, useEffect, useState } from 'react';

/**
 * 簡易的な認証情報の型のサンプル
 */
export type AuthInfo = {
  user_id: string;
  login_id: string;
  tenant_id: string;
  platform_env: string;
  user_name?: string;
  remember: boolean;
};

const empty_auth_info: AuthInfo = {
  user_id: '',
  login_id: '',
  tenant_id: '',
  platform_env: '',
  remember: false,
};

// ログイン状態のContext
export const LoggedInContext = React.createContext<{
  logged_in: boolean;
  loading: boolean;
}>({
  logged_in: false,
  loading: true,
});

// 認証情報と認証情報セットのContext
export const AuthInfoContext = React.createContext<{
  auth_info: AuthInfo;
  setAuthInfo: React.Dispatch<React.SetStateAction<AuthInfo>>;
  signOut: () => void;
}>({
  auth_info: empty_auth_info,
  setAuthInfo: () => {},
  signOut: () => {},
});

export const AuthContextProvider = (props: { children?: React.ReactNode }) => {
  // stateの定義
  // ログイン状態かどうか
  const [logged_in, setLoggedIn] = useState<boolean>(false);
  // session strageから情報を取得済みの場合はfalse, それ以外がtrue
  const [loading, setLoading] = useState<boolean>(true);
  // ユーザー情報
  const [auth_info, setAuthInfo] = useState<AuthInfo>(getDefaultAuthInfo());

  const signOut = useCallback(() => {
    // 記憶する場合は、user_id, user_name以外保持
    if (auth_info.remember) {
      const new_auth_info: AuthInfo = {
        ...empty_auth_info,
        platform_env: auth_info.platform_env,
        remember: auth_info.remember,
        login_id: auth_info.login_id,
        tenant_id: auth_info.tenant_id,
      };
      setAuthInfo(new_auth_info);
      setAutoInfoToLocalStorage(new_auth_info);
    } else {
      setAuthInfo(empty_auth_info);
      // session strageのauth_infoを削除する
      window.sessionStorage.removeItem('auth_info');
    }
  }, [auth_info]);

  // authInfoのバリデーション
  useEffect(() => {
    setLoading(true);
    // authInfoに正しく値がセットされているかどうかをチェック
    if (auth_info?.user_id) {
      setAutoInfoToLocalStorage(auth_info);
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
    setLoading(false);
  }, [auth_info]);
  return (
    <LoggedInContext.Provider value={{ logged_in, loading }}>
      <AuthInfoContext.Provider value={{ auth_info, setAuthInfo, signOut }}>{props.children}</AuthInfoContext.Provider>
    </LoggedInContext.Provider>
  );
};

/**
 * デフォルトのAuthInfoを取得
 * ローカルストレージから取得できた場合はその値をパース
 * 取得できない場合は空の情報を返す
 * @returns
 */
const getDefaultAuthInfo = (): AuthInfo => {
  const defaultAuthInfo = window.sessionStorage.getItem('auth_info');
  if (defaultAuthInfo) {
    return JSON.parse(defaultAuthInfo) as AuthInfo;
  } else {
    return empty_auth_info;
  }
};

/**
 * 認証情報をローカルストレージに追加
 * @param auth_info
 */
const setAutoInfoToLocalStorage = (auth_info: AuthInfo): void => {
  const authInfoStringfy = JSON.stringify(auth_info);
  window.sessionStorage.setItem('auth_info', authInfoStringfy);
};
