/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { AuthInfoContext } from 'hooks/authContext/authContext';
import React from 'react';

/**
 * AuthContextをreactコンポーネント外から操作するためのクラス
 * 現状無理やりやってる感があるが致し方ない...
 * **/
export default class AuthContextUpdater extends React.Component {
  static instance?: AuthContextUpdater = undefined;
  static contextType = AuthInfoContext;
  componentDidMount() {
    AuthContextUpdater.instance = this;
  }
  componentWillUnmount() {
    AuthContextUpdater.instance = undefined;
  }
  static signOut() {
    // @typescript-eslint/no-explicit-any
    const context: any = AuthContextUpdater.instance?.context;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (context && context.signOut) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      context.signOut();
    }
  }
  render() {
    return <></>;
  }
}
AuthContextUpdater.contextType = AuthInfoContext;
