import React, { SetStateAction, useState } from 'react';

const useString = (def?: string): [string, React.Dispatch<SetStateAction<string>>] => {
  const [str, setStr] = useState<string>(getInitialStr(def));
  React.useEffect(() => {
    setStr(getInitialStr(def));
  }, [def]);
  return [str, setStr];
};

export default useString;

const getInitialStr = (def?: string): string => {
  return def || '';
};
