/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// -- basic library --
import React from 'react';
import styled from 'styled-components';

// -- external components --
import ConfirmText from './ConfirmText';
import ToggleButton from './ToggleButton';
import styles, { table_cell_toggle_button_style } from 'utils/styles';
import StateText from './StateText';
import FunctionalText from 'components/atoms/FunctionalText';
import { isValueUrl } from 'utils/baseTableFunctions';
import { useNavigate } from 'react-router-dom';

// -- type declaration --
interface Params {
  key_name: string;
  value: any;
}

// -- main component --
/**
 * テーブルでデータを表示させる際、好きなkeyに対して好きな表示方法にする
 */
const DisplayDataForTable: React.FC<Params> = (params) => {
  // 遷移を司るhooks
  const navigate = useNavigate();

  // -- render part --
  if (params.key_name === 'confirmed') {
    return <ConfirmText confirmed={params.value === 'true'} />;
  } else if (params.key_name === 'status_icon') {
    return <StateText state={params.value === 'ACTIVE' ? 'ACTIVE' : 'PAUSE'} />;
  } else if (params.key_name === 'changeable_status') {
    return (
      <ToggleButton
        onClick={
          ((e) => {
            params.value.onStatusChange();
            // 親から伝達されるイベントを止める
            e.stopPropagation();
          }) || undefined
        }
        checked={params.value.status ? params.value.status : false}
        style={table_cell_toggle_button_style}
      />
    );
  } else if (params.key_name === 'channel_names' && Array.isArray(params.value)) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          maxWidth: '100%',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
        title={params.value.join()}
      >
        {params.value.map((v, index) => (
          <div key={index} style={{ marginRight: styles.interval_narrow_margin }}>
            {v}
          </div>
        ))}
      </div>
    );
  } else if (params.key_name === 'comment' && params.value.created_at && params.value.comment) {
    return (
      <div>
        <Text title={params.value.created_at} style={{ fontSize: '0.4em' }}>
          {params.value.created_at}
        </Text>
        <Text title={params.value.comment}>{params.value.comment}</Text>
      </div>
    );
  } else if (Array.isArray(params.value)) {
    return (
      <div>
        {params.value.map((v, index) => (
          <Text key={index} title={v}>
            {v}
          </Text>
        ))}
      </div>
    );
  } else if (isValueUrl(params.value)) {
    // params.valueがValueUrl型の場合は、リンク付きで返却する
    const text = params.value.value || '';
    const url = params.value.url || '';
    const onClick = () => {
      navigate(url);
    };
    return (
      <FunctionalText title={String(text)} text={String(text)} onClick={onClick} style={{ fontSize: 'inherit' }} />
    );
  } else if (typeof params.value === 'string' || typeof params.value === 'number') {
    return <Text title={String(params.value)}>{params.value}</Text>;
  } else {
    return <>{params.value}</>;
  }
};

// -- styled components --

const Text = styled.div`
  align-items: center;
  font-family: inherit;
  color: inherit;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

// -- finally export part --

export default DisplayDataForTable;
