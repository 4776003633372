// ストリームデータのサムネイル画像を取得して表示します
import { Spinner } from '@blueprintjs/core';
import { trainingDatasImageGetAPI } from 'api/training-datas';
import useStrNull from 'hooks/useStrNull/useStrNull';
import { ImgHTMLAttributes, useCallback, useEffect } from 'react';
import s3key_convert from 'utils/letter_convert';

/** image_s3_keyでもって、サムネイルを表示するコンポーネント**/
interface TrainingDataThumbnailImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  image_s3_key: string;
}
export default function TrainingDataThumbnailImage(props: TrainingDataThumbnailImageProps) {
  const [thumbnail, setThumbnail] = useStrNull();

  /** サムネイルをロードする**/
  const loadThumbnail = useCallback(async () => {
    // ロード開始時はnullにする
    setThumbnail(null);
    const res = await trainingDatasImageGetAPI({
      image_s3_key: s3key_convert(props.image_s3_key),
    });
    if (res) {
      // ローカルでは画像リンクを直接挿入
      if (process.env.NODE_ENV !== 'production') {
        setThumbnail('https://www.ichibanya.co.jp/assets/images/menu/index-pic01.png');
        return;
      }
      const blob = new Blob([res], { type: 'image/png' });
      const url = window.URL || window.webkitURL;

      setThumbnail(url.createObjectURL(blob));
    } else {
      // 通信に失敗してもロードが終わるように空文字列を挿入
      setThumbnail('');
    }
  }, [props.image_s3_key, setThumbnail]);

  // マウント時ロード処理
  useEffect(() => {
    void (async function () {
      await loadThumbnail();
    })();
  }, [loadThumbnail]);
  return typeof thumbnail === 'string' ? <img src={thumbnail} alt="サムネイル" {...props} /> : <Spinner />;
}
