// -- basic library --
import styles from 'utils/styles';
import React from 'react';
import styled from 'styled-components';

// -- external components --
import { colors } from 'utils/colors';

// -- external datas --

// -- type declaration --
export interface FileBoxProps {
  id?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void; // eventごと利用する
  title?: string;
  button_text?: string;
  accept?: string;
  file_name?: string;
  test_id?: string;
  placeholder?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  multiple?: boolean;
}

// -- main component --

const FileBox = (props: FileBoxProps) => {
  const id = props.id || 'file-component';
  // -- render part --
  return (
    <StyledFileBox disabled={props.disabled} style={props.style}>
      {props.file_name ? props.file_name : <PlaceholderArea>{props.placeholder}</PlaceholderArea>}
      <Label htmlFor={id} disabled={props.disabled} hide_button={!props.button_text}>
        <Input
          id={id}
          title={props.title}
          type="file"
          onClick={(e) => {
            e.currentTarget.value = '';
          }}
          accept={props.accept}
          multiple={props.multiple}
          onChange={props.onChange}
          disabled={props.disabled}
        />
        {props.button_text}
      </Label>
    </StyledFileBox>
  );
};

// -- styled components --
const wholearea_disabled_styles = `{
  opacity: 1;
  background-color: ${colors.disabled_background_color};
  cursor: not-allowed;
}`;

const StyledFileBox = styled.div<{
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  overflow: scroll;
  white-space: nowrap;
  width: 100%;
  padding: ${styles.input_box_padding};
  font-family: inherit;
  height: ${styles.input_component_height};
  box-sizing: border-box;
  border: 1px solid ${colors.component_small_border_color};
  border-radius: ${styles.border_radius};
  outline: none;
  position: relative;
  background-color: ${colors.white};
  &:focus {
    border-color: ${colors.selected_border_color};
  }
  ${(params) => (params.disabled ? wholearea_disabled_styles : null)};
`;

const PlaceholderArea = styled.div`
  color: ${colors.placeholder_color};
  font-family: inherit;
  font-weight: normal;
`;

const Input = styled.input`
  display: none;
`;

const Label = styled.label<{
  disabled?: boolean;
  hide_button?: boolean;
}>`
  display: ${(p) => (p.hide_button ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  width: 120px;
  height: calc((2 * ${styles.input_component_height}) / 3);
  border-radius: 25px;
  background-color: ${colors.component_main_color};
  color: ${colors.component_text_color};
  border: none;
  position: absolute;
  right: 10px;
  opacity: ${(params) => (params.disabled ? styles.opacity_disabled : 1)};
  top: calc((${styles.input_component_height} - ((2 * ${styles.input_component_height}) / 3)) / 2);
  &:hover {
    opacity: ${(params) => (params.disabled ? styles.opacity_disabled : styles.opacity_hover)};
    cursor: ${(params) => (params.disabled ? 'not-allowed' : 'pointer')};
  }
  font-weight: bold;
`;

// -- finally export part --

export default FileBox;
