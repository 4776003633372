import { Spinner } from '@blueprintjs/core';
import { useCallback, useEffect, useState } from 'react';

import RoundedButton from 'components/atoms/RoundedButton';
import FileCardDisplayingTrainingData from 'components/organisms/FileCardDisplayingTrainingData';
import { CardArea, Title, TopArea, TopButtonArea, WholeArea } from 'components/atoms/ContentsArea';

import useIsOpen from 'hooks/useIsOpen/useIsOpen';
import CreateDatasetDialog from 'dialogs/CreateDatasetDialog';
import {
  resetTrainingDatasetCartDeleteAPI,
  TrainingDatasetCart,
  trainingDatasetCartDeleteAPI,
  trainingDatasetCartPagingGetAPI,
} from 'api/training-datasets-carts';
import PageTurningComponentForTrainingDataTable from 'components/molecules/PageTurningComponentForTrainingDataTable';
import ConfirmDialog from 'components/organisms/ConfirmDialog';
import ProcessingDialog from 'dialogs/ProcessingDialog';

// 一度に取得する学習データの個数
const GET_TRAINING_DATA_LIMIT = 100;

// 1ページに表示する学習データの個数
const TRAINING_DATAS_PER_PAGE = 10;

// ページ切り替えボタンの数字の表示数
// 例えば5ならば <- 1 2 3 4 5 -> となる
const page_display_number = 5;

/** カート画面 **/
export default function Carts() {
  // ダイアログのopenを司るstate
  const [is_open, { onClose, onOpen }] = useIsOpen(false);
  const [trainingDatasetCart, setTrainingDatasetCart] = useState<TrainingDatasetCart[] | undefined>(undefined);
  const [exclusive_start_key, setExclusiveStartKey] = useState<number | undefined>(undefined);
  const [has_next, setHasNext] = useState<boolean>(false);
  const [selected_page_number, setselected_page_number] = useState<number>(1);
  const [is_processing_dialog_open, { onClose: onProcessingDialogClose, onOpen: onProcessingDialogOpen }] = useIsOpen();

  // 学習データ一覧を取得
  const getTrainingDatasetCartItems = async () => {
    const res = await trainingDatasetCartPagingGetAPI({
      limit: GET_TRAINING_DATA_LIMIT,
    });
    if (res) {
      setTrainingDatasetCart(res.items);
      setExclusiveStartKey(res.last_evaluated_data_id ? res.last_evaluated_data_id : undefined);
      setHasNext(res.has_next);
    }
  };

  // 次ページの学習データを追加
  const addDatas = async () => {
    // 次のデータがなければ、この関数を呼ばない
    if (!has_next || !exclusive_start_key) {
      return;
    }
    const res = await trainingDatasetCartPagingGetAPI({
      limit: GET_TRAINING_DATA_LIMIT,
      exclusive_start_key: exclusive_start_key,
    });
    if (res) {
      let training_datas = [];
      if (trainingDatasetCart === undefined) {
        training_datas = res.items;
      } else {
        training_datas = [...trainingDatasetCart, ...res.items];
      }
      setTrainingDatasetCart(training_datas);
      setExclusiveStartKey(res.last_evaluated_data_id ? res.last_evaluated_data_id : undefined);
      setHasNext(res.has_next);
    }
  };

  // カートをリセットするAPIを叩く
  const resetTrainingDataItems = () => {
    ConfirmDialog.show('カートを空にしてもよろしいですか？', () => handleResetClick(), undefined, undefined);
  };

  const handleResetClick = useCallback(async () => {
    onProcessingDialogOpen();
    const res = await resetTrainingDatasetCartDeleteAPI();
    // 成功したらデータを再取得
    if (res) {
      await getTrainingDatasetCartItems();
    }
    onProcessingDialogClose();
  }, []);

  // カートの中から、指定のdata_idのtraining-dataを削除する
  const deleteTrainingData = useCallback(async (data_id: number) => {
    const res = await trainingDatasetCartDeleteAPI({
      data_id: data_id,
    });
    if (res) {
      await getTrainingDatasetCartItems();
    }
  }, []);
  useEffect(() => {
    void (async function () {
      await getTrainingDatasetCartItems();
    })();
  }, []);

  return (
    <WholeArea>
      <TopArea>
        <Title text="カート" />
      </TopArea>
      <TopButtonArea>
        <RoundedButton
          text="カートを空にする"
          onClick={resetTrainingDataItems}
          is_margin_right={true}
          disabled={trainingDatasetCart?.length === 0}
        />
        <RoundedButton text="データセット作成" onClick={onOpen} disabled={trainingDatasetCart?.length === 0} />
      </TopButtonArea>
      <div style={{ width: '300px', margin: 'auto' }}>
        {trainingDatasetCart ? (
          <PageTurningComponentForTrainingDataTable
            handleAddDatas={() => addDatas()}
            has_next={has_next}
            handleClick={setselected_page_number}
            selected_page_number={selected_page_number}
            bodies_per_page={TRAINING_DATAS_PER_PAGE}
            bodies={trainingDatasetCart}
            page_display_number={page_display_number}
          />
        ) : undefined}
      </div>
      <CardArea>
        {trainingDatasetCart ? (
          trainingDatasetCart
            .slice((selected_page_number - 1) * TRAINING_DATAS_PER_PAGE, selected_page_number * TRAINING_DATAS_PER_PAGE)
            .map((data, index) => {
              return (
                <FileCardDisplayingTrainingData
                  deleteTrainingData={() => deleteTrainingData(data.data_id)}
                  data_id={data.data_id}
                  image_s3_key={data.image_s3_key}
                  tag_list={data.tag_list}
                  key={index}
                />
              );
            })
        ) : (
          <div>
            ～取得中～ 10秒ほどお待ちください
            <Spinner />
          </div>
        )}
      </CardArea>
      {is_open && <CreateDatasetDialog isOpen={is_open} onClose={onClose} />}
      {is_processing_dialog_open && (
        <ProcessingDialog
          isOpen={is_processing_dialog_open}
          onClose={onProcessingDialogClose}
          title="カートの中身を削除中"
        />
      )}
    </WholeArea>
  );
}
